.progress-sections {
  flex: 1;
  --progress-line-shadow: 2px 2px 8px 0px var(--color5);
  --progress-line-incomplete: var(--grey);
  --progress-line-completed: var(--dark-orange);
  --progress-line-done: var(--success);
  --progress-line-color: white;

  &.done {
    .progress-bar__step {
      background: var(--progress-line-done);
    }
  }

  &__caption {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 5px;

    .--label {
      text-align: center;
      font-size: var(--text-small);
      color: #999;
      padding: 0 5px;
    }
    .--percents {
      font-size: var(--text);
      color: var(--progress-line-color);
    }
  }

  &__step {
    height: 10px;
    margin: 2px;
    text-align: center;
    position: relative;
    border-radius: 50vh;
    box-shadow: var(--progress-line-shadow);
    display: flex;

    .incomplete,
    .complete,
    .done {
      flex: 1;
      background: var(--progress-line-incomplete);
      margin-right: 1px;
      box-shadow: var(--progress-line-shadow);
      transition: 0.3s;

      &:first-child {
        border-top-left-radius: 50vh;
        border-bottom-left-radius: 50vh;
      }

      &:last-child {
        border-top-right-radius: 50vh;
        border-bottom-right-radius: 50vh;
      }
    }

    .complete {
      background: var(--progress-line-completed);
    }

    .done {
      background: var(--progress-line-done);
    }

    &.multi-level {
      // background: var(--grey);
    }

    .--progress {
      transition: 0.5s;
      background: var(--progress-line-done);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-radius: 50vh;
      box-shadow: var(--progress-line-shadow);
    }

    .--completed {
      transition: 0.5s;
      background: var(--progress-line-completed);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-radius: 50vh;
      box-shadow: var(--progress-line-shadow);
    }
  }

  &.small {
    .progress-sections__step {
      height: 5px;
      border-radius: 0;
    }
  }

  &.dark {
    --progress-line-shadow: none;
    --progress-line-incomplete: #aaa;
    --progress-line-color: black;
  }
}
