.document-edit {
  --light-bg: #f2f5fa;
  --btn-grey: #a9b6c2;
  --grey-2: #afafaf;
  --grey-3: rgb(95, 99, 104);
  --border-radius: 2px;

  --green: #73ccac;
  --orange: #ffc385;
  --blue: #98aee6;
  --blue-2: #6c87cd;
  --blue-3: #5961f9;
  --red: #ff7ca6;
  --red-light: #ff7ca60d;

  background: var(--light-bg);
  color: black;
  display: grid;
  grid-template-columns: 1fr 800px 2fr;
  grid-template-rows: auto 1fr;
  height: 100%;

  &__content {
    padding-bottom: 4em;
  }

  &__header {
    position: sticky;
    top: 0;
    z-index: 30;
    grid-row: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    &.--a {
      grid-column: 1;
      padding: 0 1em;
    }
    &.--b {
      grid-column: 2;
    }
    &.--c {
      grid-column: 3;
    }

    &__bg {
      background: white;
      position: sticky;
      top: 0;
      z-index: 30;
      box-shadow: 0px 4px 32px rgba(59, 46, 46, 0.15);
      grid-column: 1 / -1;
      grid-row: 1;
    }
  }

  .--draft {
    display: inline-flex;
    background: var(--inviting);
    color: white;
    padding: 0 1em;
    border-radius: 50vh;
    font-size: var(--text-small);
  }
  //------------------------------------------------------
  // left side
  //------------------------------------------------------

  @media screen and (max-width: 1370px) {
    grid-template-columns: 700px 2fr;

    .--b {
      grid-column: 1;
      margin-left: 100px;
    }
    .--c {
      grid-column: 2;
    }

    &__left {
      display: none;
    }

    .rte-editor .block-menu {
      transform: translateY(calc(-100% - 1em));
    }
  }
  //------------------------------------------------------

  &__left {
    padding: 1.5em 1em;

    &__content {
      position: sticky;
      top: 80px;
    }
  }

  &__aside {
    padding: 1.5em 1em;
    max-height: calc(100vh - 61px);
    // height: 100%;
    overflow: auto;
    position: sticky;
    top: 61px;
    padding-bottom: 270px;
  }

  &__content {
    .ProseMirror {
      // box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
      box-shadow: 0px 0px 5px 0px #ccc;
      border: none;
      padding: 3em;
      padding-top: 5em;
      border-radius: var(--border-radius);
      margin: 0;
      min-height: calc(100vh - 310px);

      p {
        white-space: pre-wrap;
      }
    }

    .ProseMirror-menubar {
      box-shadow: 0px 0px 5px 0px #ccc;
      // margin-top: 1em;
      border: none;
      float: none;
      border-radius: 0;
      margin: 0;
      padding: 0.5em 1em;
      // background-color: var(--light-grey);
      background: white;
      position: sticky;
      top: 60px;
    }

    [dr-each] {
      [dr-text] {
        background: var(--inviting);
      }
      [dr-signature] {
        outline: solid 2px var(--inviting);
      }
    }

    [dr-text] {
      background: var(--success-dark);
      color: white;
      border-radius: 3px;
      padding: 1px 0.25em;
      align-items: center;
      margin: 2px;
      cursor: pointer;
    }

    [dr-text=''] {
      background: var(--dark-orange);
    }

    [dr-when],
    [dr-default] {
      outline: dashed 1px var(--warning);
      padding: 0.2em;
      margin: 3px;
    }

    [dr-default] {
      outline: dashed 1px var(--dark-orange);
    }

    [dr-signature] {
      outline: solid 2px var(--green);
      height: 90px;
      width: 300px;
      border-radius: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      user-select: none;
      background: var(--light-blue);

      span {
        display: flex;
        height: 100%;
        width: 100%;
        flex-direction: column;
        align-items: center;
        justify-content: space-evenly;

        &:after {
          content: '';
        }
      }

      &[draggable] {
        background: var(--light-grey);
      }
    }

    [data-page-break] {
      border-bottom: solid 1px #ddd;
      text-align: center;
      color: #ccc;
      font-size: 11px;
      margin: 1rem -2.5rem;

      &:before {
        content: 'Page break';
      }
    }
  }

  &__box {
    background: white;
    box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
    padding: 1em;
    border-radius: var(--border-radius);
    position: relative;
    // top: 80px;

    header {
      display: flex;
      align-items: center;
      gap: 1em;

      i,
      b {
        background: var(--btn-grey);
        height: 40px;
        width: 40px;
        border-radius: 5px;
        display: flex;
        align-items: center;
        justify-content: center;
        color: white;
        flex-shrink: 0;
      }
    }

    h1 {
      font-size: var(--text-medium);
    }

    .user-radio {
      .radio__item {
        padding: 0 0.5em;
      }

      .user-card {
        margin: 0;
        background: none;
      }
    }
  }

  .history {
    padding: 1em 0;

    h2 {
      padding: 0.5rem;
    }

    &__version {
      min-width: 120px;
    }

    .desc {
      white-space: nowrap;
    }

    .dropdown__selected__label {
      padding: 0.5em 1em;
    }
  }

  .notification {
    background: var(--warning);
    padding: 1em 2em;
    border-radius: 3px;
    color: var(--dark2);

    &__content {
      display: flex;
      align-items: flex-end;
      justify-content: space-between;
      gap: 2em;

      p {
        margin-bottom: 0;
      }
    }
  }
}
