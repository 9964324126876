.settings {
  display: flex;
  height: 100%;
  background: var(--lightest);

  &__nav {
    width: 200px;
    display: flex;
    flex-direction: column;
    font-size: var(--text-medium);
    margin-bottom: 20px;
    background: var(--primary);
    border-bottom-right-radius: 18px;
    padding-top: 20px;
    color: white;

    &__item {
      padding: 20px;
      position: relative;
      margin: 5px 0;

      a {
        display: flex;
        gap: 1em;
      }

      a:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:hover {
        background: var(--medium);
      }
      &.active {
        background: var(--lightest);
        color: black;

        svg {
          color: var(--dark);
        }
      }
    }
  }
}
