.aside {
  // background: white;
  padding-left: 1em;
  padding-bottom: 100px;

  .form-label {
    font-size: 11px;
    color: #999;
  }

  .form-input input {
    font-size: 14px;
    padding: .5em 1em;
    border: solid 1px #eee;
    background: #f9f9f9;
  }

  .form-item {
    margin: .25em 0;

    h3 {
      padding: .5em;
      font-weight: bold;
      background: var(--light-bg);
    }
  }

  .form-array .array__item {
    align-items: center;
    gap: .5em;

    &:nth-child(odd) {
      background: none;
    }

    svg {
      width: 18px;
      height: 18px;
    }

    .--nr {
      background: none;
      color: var(--blue-2);
    }
  }

  .form-group {
    --bg: none;
  }

  .pre input {
    font-family: monospace;
    font-size: 12px;
  }

  &__tabs {
    // border-bottom: solid 1px #ccc;
    background: var(--light-grey);
    position: sticky;
    top: 0px;
    z-index: 10;
    // background: white;
    // box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 1em;
    margin-left: 1em;

    .tabs {
      display: flex;
      align-items: center;
      gap: .5em;

      .tab {
        padding: 5px 10px;
        background: none;
        color: black;

        &.active {
          background: var(--btn-grey);
          color: white;
        }
      }
    }
  }
}

.test {
  &__item {
    background: white;
    padding: .5em;
    margin: 3px;
    border-radius: 5px;
  }

  &__children {
    margin: 1em;

    li {
      padding: .5em;
      background: var(--blue);
      margin: 3px;
      border-radius: 2px;
    }
  }
}

.document-edit__aside {
  position: relative;
  &__list {
    li {
      display: flex;
      align-items: center;
      gap: 1em;
      padding: .5rem;
    }

    label {
      width: 150px;
    }

    div {
      font-weight: 500;
    }
  }

  h2 {
    margin: 1rem .5rem;
    // color: var(--primary);
  }

  &__actions {
    float: right;
  }

  &__overlay {
    position: absolute;
    background: #000000ba;
    color: white;
    inset: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    z-index: 1;

    h3 {
      padding: 1em;
    }

    &__actions {
      display: flex;
      align-items: center;
      gap: .5em;
    }
  }
}
