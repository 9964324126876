.ani__history {
  padding: .5em;
  display: flex;
  align-items: flex-start;
}

.party {
  &__header {
    display: flex;
    align-items: center;
    gap: .5em;

    svg {
      color: var(--dark-orange);
    }

    &.isTurn.isTurn {
      background: none;
      svg {
        color: var(--success);
      }
    }
  }

  &__history {
    margin: .5em 2em;
    padding: 0 1em;
    display: flex;
    align-items: center;
    gap: 1em;
    border-radius: 50vh;

    &.latest {
      &.myParty {
        background: var(--my-party);
      }

      &.counterparty {
        background: var(--cp-bg);
      }

      &.col-1 {
        &.myParty {
          background: var(--cp-bg);
        }

        &.counterparty {
          background: var(--my-party);
        }
      }
    }
  }

  &__activity {
    margin: .5em 0;
    padding: .5em;
    border: solid 1px var(--lighter);
    background: white;
    border-radius: 3px;
    position: relative;

    &__user {
      // border: solid 1px red;
      position: absolute;
      right: 0;
      top: 0;
    }

    .version {
      background: var(--dark);
      color: white;
      padding: 0 .5em;
      border-radius: 5vh;
    }
  }
}
