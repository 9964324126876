.smart-id {
  // display: flex;
  // align-items: center;
  // justify-content: space-between;
  margin: 1em;
  margin-top: 0;
  color: var(--dark);

  .ECONNABORTED,
  .TIMEOUT {
    background: #ffb86f87;
    padding: 2em 2em;
    width: 100%;

    h4 {
      color: var(--darker);
      button {
        display: block;
      }
    }
  }

  .NOT_FOUND,
  .SIGNED_BY_OTHER,
  .WRONG_VC,
  .USER_REFUSED,
  .USER_REFUSED_DISPLAYTEXTANDPIN,
  .USER_REFUSED_CONFIRMATIONMESSAGE {
    background: #e8533d87;
    padding: 2em 2em;

    h4 {
      color: var(--darker);
      button {
        display: block;
      }
    }
  }
  .SIGNED_BY_OTHER h4 button {
    display: none;
  }

  .OK,
  .SIGNED {
    background: #73ccac87;
    padding: 2em;
  }

  .--content {
    border-radius: 3px;
    display: flex;
    padding: 1em;
    color: var(--darker);
    gap: 2em;
  }

  &__icon {
    color: var(--smart-id-color);
  }

  &__pending {
    border-radius: 5px;
    padding: 1em;
    color: var(--darker);
    display: flex;
    flex-direction: column;
    gap: 1em;

    h4 {
      flex: 1;
      display: flex;
      gap: 2em;
      align-items: center;
      justify-content: space-between;
      padding: 0;
    }

    button {
      display: none;
    }

    .--code {
      background: var(--grey);
      padding: .75rem 1.5rem;
      font-size: var(--text-big);
      display: inline-flex;
      border-radius: 3px;
      font-weight: 600;
      letter-spacing: 3px;
      color: var(--dark);
      border: solid 1px var(--dark);
      display: inline-flex;
    }
  }

  &__actions {
    margin: .5em;
    display: flex;
    align-items: center;
    gap: .5em;
  }

  .error,
  .success {
    padding: 2em;
    margin: 1em 0;
    font-size: var(--text-medium);
    background: var(--error2);
    color: white;
    border-radius: 5px;
  }

  &__success {
    background: none;
    line-height: 1.8em;
    position: relative;
    padding: 1em;
  }

  &__more-actions {
    position: absolute;
    right: 1.5em;

    .dropdown__open {
      &:before {
        right: 18px;
      }
    }
  }
}
