.form {
  &-item {
    margin: .5em 0;
    border-radius: 5px;
    display: flex;
    flex-direction: column;
    position: relative;
    max-width: 500px;

    &.nested {
      animation: fadein 0.3s;
    }

    &__desc {
      font-size: var(--text-small);
      color: #999;
      margin: 0 6px;
    }

    &__actions {
      margin-top: 10px;
      display: flex;
      align-items: center;
    }
  }

  &-label {
    padding: 5px;

    font-size: var(--text-medium);
    display: flex;
    justify-content: space-between;
    align-items: center;

    .required {
      color: var(--error);
      &.isValid {
        color: var(--dark);
      }
    }

    .form-actions {
      margin: 0;
      border-radius: 50vh;
      // color: #ccc;
      // color: var(--primary-btn);
      color: var(--dark);

      svg {
        width: 16px;
        height: 16px;
      }

      .--changed {
        color: var(--medium);
        display: flex;
      }

      .--more {
        .dropdown__selected {
          visibility: hidden;
          &.open {
            visibility: visible;
          }
        }
      }
    }
  }

  &-actions {
    display: flex;
    align-items: center;
    gap: 10px;
    margin-top: 20px;
  }

  &-input {
    flex: 1;
    border: none;
    display: flex;
    flex-direction: column;

    .flex {
      input {
        flex: 1;
      }
    }

    input,
    select {
      z-index: 1;
      position: relative;
    }

    .readOnly {
      background: none;
      border-color: var(--read-only-border);
      color: #333;
      --matched-border2: var(--read-only-border);
      --my-border: var(--read-only-border);
    }

    .ProseMirror,
    textarea,
    select,
    input {
      padding: .5em 1em;
      border-radius: 6px;
      // flex: 1;
      font-family: 'Poppins';
      font-size: var(--text);
      border-color: #ccccccb5;

      &:focus {
        outline: none;
      }
    }

    h2 {
      color: var(--warning);
      font-size: var(--text-big);
      font-weight: normal;
    }

    &__value {
      flex: 1;
      padding: 10px;
      border: solid 1px #ccc;
      border-radius: 6px;
      font-size: var(--text);
      border: none;

      &.textarea {
        min-height: 100px;
      }

      &.matched {
        background: var(--light-green);
      }

      &.empty {
        background: none;

        &:before {
          content: '-- empty --';
          color: #ccc;
        }
      }
    }

    &__view-only {
      // border: solid 1px red;
      padding: 10px 10px 9px;
      font-size: var(--text-medium);
      border-left: solid 2px var(--grey);
      margin-left: 5px;
      font-weight: bold;
      background: var(--lightest);
      border-radius: 2px;

      li {
        list-style: disc;
        margin: 0px 20px;
        padding: 3px;

        & ~ li {
          margin-top: 10px;
        }
      }
    }
  }

  &-columns {
    display: flex;
    flex-wrap: wrap;
    & > div ~ div {
      margin-left: 5px;
    }

    & > div {
      flex: 1;
    }
  }

  &-compare {
    --compare-border: orange;
    // --compare-bg: var(--cp-bg);
    // --compare-bg: var(--grey);
    --compare-bg: #f6eddc;
    --compare-text: black;

    margin: 10px 3px;
    border-radius: 5px;
    padding: .5em;
    // padding-top: 5px;
    background: var(--compare-bg);
    position: relative;
    white-space: pre;
    font-size: 13px;
    white-space: pre-wrap;
    color: var(--compare-text);

    // display: flex;
    // align-items: flex-start;
    // flex-direction: row-reverse;
    // gap: .5em;

    .dropdown__selected {
      // border: solid 1px red;
      padding: 4px;
      border-radius: 50vh;
      cursor: pointer;

      &.open,
      &:hover {
        background: var(--light-grey);
      }
    }

    .dropdown {
      float: right;
      svg {
        height: 16px;
        width: 16px;
      }
    }

    &__content {
      // border: solid 1px red;
      flex: 1;
      padding: 3px .5em;
      padding-bottom: 0;
    }

    &__header {
      // margin-left: -5px;
      // margin-bottom: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      position: relative;
    }

    &:before {
      content: '';
      position: absolute;
      width: 16px;
      height: 16px;
      left: 30px;
      top: -7px;
      background: var(--compare-bg);
      transform: rotate(45deg);
    }

    label {
      font-size: 10px;
      // position: absolute;
      // border: solid 2px var(--compare-bg);
      background: white;
      color: black;
      // top: 3px;
      // left: 2px;
      padding: 0 1em;
      border-radius: 50vh;
      text-transform: uppercase;
      font-weight: 300;
      // font-family: monospace;
      letter-spacing: 1px;
    }

    button {
      text-transform: uppercase;
      background: white;
      color: var(--compare-border);
      padding: 1px 5px;
      position: absolute;
      right: 10px;
      bottom: -5px;
      border: solid 2px var(--compare-bg);
      font-size: 10px;
      cursor: pointer;
      border-radius: 50vh;
    }

    &.main {
      --compare-border: var(--secondary);
      --compare-bg: var(--dark);
    }

    &.version {
      --compare-border: var(--secondary);
      --compare-bg: var(--medium);
      --compare-text: white;
    }
  }

  // .required {
  //   color: red;
  //   &.isValid {
  //     color: var(--lighter);
  //   }
  // }
}

pre {
  margin: 3px 0;
  font-size: 10px;
}

.form-inline {
  .radio__children {
    border-left: solid 1px var(--dark);
    margin-left: 15px;
    padding: 0px 15px;
  }
  .form-item__content {
    // border: solid 1px red;
    display: flex;
    flex-direction: row;
    // align-items: flex-start;

    .form-label {
      width: 200px;
      font-weight: bold;
      color: var(--darker);
    }

    .form-input {
      max-width: 600px;
    }
  }
}
