.neg-settings {
  .me,
  .cp {
    display: flex;
    color: black;

    border-radius: 5px;
    padding: 10px;
    margin-bottom: 2em;
    gap: 2em;
    background: var(--lightest);

    &__content {
      flex: 1;
      display: flex;
      flex-direction: column;
      gap: 1em;

      i {
        color: var(--medium);
      }
    }

    &__name {
      font-size: var(--text-medium);
    }

    &__email {
      margin-top: 5px;
      font-size: var(--text);
      color: var(--dark);
    }

    &__permissions {
      &__list {
        margin: 1em 0;
      }

      li {
        margin: 0.5em;
        color: var(--dark);
        display: flex;
        align-items: center;
        gap: 0.5em;

        svg {
          display: block;
          height: 16px;
          width: 16px;
        }

        &.not-allowed {
          svg {
            color: var(--error);
          }
          opacity: 0.5;
        }
      }
    }

    &__last-updated {
      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .cp {
    background: var(--cp-bg);
    display: block;

    &__main {
      display: flex;
      justify-content: space-between;
      flex: 1;
    }

    &__view {
      display: flex;
      flex: 1;
      gap: 2em;
    }

    &__actions {
      display: flex;
      margin: 5px;
      margin-top: 1em;
    }

    &__comment {
      label {
        margin: 1em 5px 0.5em;
        display: block;
      }
    }

    &__reminder {
      h4 {
        margin: 5px;
        font-size: var(--text-medium);
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      textarea {
        width: 100%;
        height: 100px;
      }
    }

    &__reminder {
      border: solid 1px var(--lighter);
      padding: 1em;
      margin-top: 1em;
      border-radius: 5px;
      background: var(--light-grey);

      color: var(--dark2);

      display: flex;
      flex-direction: column;
      gap: 1em;
    }

    &__last-activity {
      background: var(--light-blue);
      // border: solid 1px var(--lighter);
      padding: 1em;
      border-radius: 5px;
      display: flex;
      flex-direction: column;
      gap: 1em;

      label {
        display: block;
        font-weight: bold;
        padding: 1em 0;
      }

      .--comment {
        display: block;
        background: var(--lightest);
        padding: 1em;
        border-radius: 3px;
        white-space: pre-wrap;
      }
    }

    &__disclaimer {
      background: var(--warning);
      border-radius: 5px;
      padding: 1em;
      color: var(--darker);
      font-weight: 500;
    }
  }
}
