.progress-bar {
  // padding-top: 5px;
  justify-content: center;
  align-items: center;

  &__list {
    display: flex;
  }

  &__item {
    flex: 1;

    &:first-child .progress-bar__step {
      border-top-left-radius: 50vh;
      border-bottom-left-radius: 50vh;
    }
    &:last-child .progress-bar__step {
      border-top-right-radius: 50vh;
      border-bottom-right-radius: 50vh;
    }

    &.done {
      .progress-bar__step {
        background: var(--success);
      }
    }
    &.inProgress {
      .progress-bar__step {
        background: var(--dark-orange);
      }
      .progress-bar__label {
        // display: none;
        font-size: var(--text);
        color: white;
      }
    }

    &.progress {
      .progress-bar__step {
        background: var(--grey);
      }
    }

    .progress-line__step {
      opacity: .5;
    }

    &.active {
      flex: 2;

      .progress-line__step {
        opacity: 1;
      }
    }
  }

  &__label {
    text-align: center;
    font-size: var(--text-small);
    color: #999;
  }

  &__step {
    height: 5px;
    // background: var(--success);
    background: var(--grey);
    margin: 2px;
    // border: solid 1px var(--grey);
    text-align: center;
    // color: black;
    position: relative;
    // border-radius: 50vh;

    .--progress {
      transition: 0.5s;
      background: var(--success);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      // border-bottom-left-radius: 10px;
    }

    .--completed {
      transition: 0.5s;
      background: var(--dark-orange);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      // border-bottom-left-radius: 10px;
    }

    .--percents {
      display: none;
      position: relative;
      z-index: 1;
    }
  }
}
