.neg-settings {
  .delete-overlay {
    background: #000000dd;
    position: absolute;
    top: 0;
    left: 10px;
    right: 0;
    bottom: 0;
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    color: white;
    gap: 2em;

    &__actions {
      display: flex;
      gap: 1em;
    }
  }

  h3 {
    padding-left: 0.5em;
    margin-bottom: 1em;
    color: var(--lighter);
  }

  .perm-list {
    color: var(--dark);
    font-size: var(--text-small);
    // margin: 1px;
    line-height: 1.5em;
    margin: 5px 0;

    li {
      list-style: disc;
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin: 1em;
    margin-top: 3em;
  }
}
