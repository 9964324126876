.assigned-users {
  // border: solid 1px red;
  position: relative;

  &__not-assigned {
    align-self: center;
  }

  &__header {
    font-weight: bold;
    color: var(--dark);
    padding: 5px;
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 10px;
  }

  &__preview {
    display: flex;
    gap: .5em;
    align-items: center;
    justify-content: flex-end;
    padding: 0 .5em;
    border-radius: 50vh;

    .--assign {
      display: flex;
      align-items: center;
      gap: .5em;
      color: var(--warning);
      color: var(--dark-orange2);
      font-weight: 500;

      button {
        background: var(--lightest);
        border-radius: 50vh;
        color: var(--primary);
        height: 28px;
        padding: 3px 1.5em;
        font-size: var(--text-small);
        display: flex;
        align-items: center;
        justify-content: center;
        border: solid 1px var(--grey);
        &:hover {
          background: white;
          color: var(--smart-id-color);
        }

      }
      &.primary button {
        background: var(--primary);
        color: white;
      }
    }
  }

  &__edit {
    &__content {
      position: absolute;
      background: var(--grey-light);
      box-shadow: 0 0 5px var(--dark2);
      border-radius: 5px;
      padding: 1em;
      margin-bottom: 0.5em;
      right: 0;
      bottom: 100%;
      z-index: 100;
      white-space: nowrap;
    }

    &__bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 100;
    }

    .--tick-icon {
      visibility: hidden;
    }

    h4 {
      margin: 1em;
      margin-top: .5em;
    }

    .--user {
      display: flex;
      align-items: center;
      gap: 1em;
      padding: .25em;
      margin: 3px 0;
      border-radius: 5px;
      cursor: pointer;
      background: white;

      &.assigned {
        background: var(--light-green);

        .--tick-icon {
          visibility: visible;
        }
      }

      &:hover {
        background: var(--lightest);
      }
    }

    .--invite {
      display: flex;
      align-items: center;
      gap: .5em;
      padding: .25em;
      margin-top: 1em;
      border-radius: 5px;
      cursor: pointer;

      &:hover {
        background-color: #eee;
      }
    }

    .--input {
      margin: .5em;

      input {
        width: 100%;
        border-radius: 5px;
      }
    }

    .--actions {
      display: flex;
      align-items: center;
      gap: .5em;
      margin: .5em 0;
      margin-top: 1em;
    }
  }
}
