.profile {
  max-width: 1100px;
  margin: 2em;

  &__avatar {
    margin-right: 20px;
    height: 115px;
    width: 120px;
    border-radius: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 48px;
    padding-bottom: 5px;
    position: relative;
    cursor: pointer;

    img {
      height: 120px;
      width: 120px;
      border-radius: 50vh;
      white-space: nowrap;
      margin-bottom: -5px;
      font-size: var(--text);
    }
  }

  &__edit-avatar {
    position: absolute;
    bottom: -5px;
    right: 0px;
    padding: .5em 1em;
    border-radius: 50vh;
    color: var(--primary-btn);
    border: solid 1px #ccc;
    background: white;
  }

  &__name {
    padding: 5px;
    border-bottom: 2px solid var(--dark);
  }

  &__info {
    display: flex;
    margin: 5px;

    &__content {
      flex: 1;
      max-width: 500px;

      li {
        margin: 5px 0;
        padding: 5px;
        display: flex;
        align-items: center;
        gap: 5px;

        svg {
          width: 18px;
          height: 18px;
          color: var(--error);
        }

        &.accepted svg {
          color: var(--success);
        }
      }

      label {
        color: var(--medium);
        width: 75px;
        display: inline-flex;
      }
    }
  }

  &__edit {
    display: flex;
    flex: 1 1;
    align-items: flex-start;
    justify-content: flex-end;
  }

  &__name {
    font-size: var(--text-big);
  }

  &__actions {
    display: flex;
    margin: 10px;
  }

  .form-item {
    max-width: 500px;
  }

  .form-input {
    margin: 0;
  }

  .form-group__children {
    margin: 5px 10px;
  }
}
