.login {
  display: flex;
  // align-items: center;
  height: 100%;

  h2 {
    display: flex;
    justify-content: space-between;
  }

  &__aside {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    background: var(--primary);
    color: white;
  }

  &__logo {
    max-width: 550px;
    text-align: center;

    svg {
      color: var(--dark-orange);
      width: 350px;
      margin-bottom: 2em;
    }

    img {
      max-width: 300px;
    }

    &__description {
      text-align: center;

      h1 {
        margin: 1em 0;
      }

      p {
        font-size: var(--text-medium);
        color: var(--light-blue);
        line-height: 1.5;
      }
    }
  }

  &__main {
    flex: 1;
    padding: 1em;
    display: flex;
    align-items: center;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 20px;
  }

  &__warning {
    display: flex;
    align-items: center;

    svg {
      height: 36px;
      width: 36px;
      margin: 0 10px;
      color: var(--warning);
    }
  }

  &__box {
    padding: 2em;
    border-radius: 1em;
    min-width: 500px;
    margin: 1em auto;
    font-size: var(--text-big);
    animation: fadein 1s;

    h1 {
      font-weight: normal;
      font-size: 28px;
      color: #777;
      flex: 1;
      text-align: center;
      margin: 20px;
      margin-top: 0;

      &:hover {
        color: #999;
        border-color: #999;
      }
    }

    .form-item__actions {
      display: flex;
      justify-content: space-between;
      align-items: center;
      flex: 1;
    }
  }

  &__message {
    font-size: var(--text);
    color: var(--warning);
    display: flex;
    align-items: center;
    justify-content: center;
    flex: 1;
  }

  &__tabs {
    display: flex;
    justify-content: center;
  }

  &__input {
    display: flex;
    padding: 5px;
    align-items: center;

    label {
      flex: 1;
      text-align: right;
      padding: 10px;
    }

    &__text {
      flex: 2;

      input {
        font-size: 19px;
        border-radius: 10px;
      }

      button {
        font-size: 19px;
        border-radius: 10px;
      }
    }
  }

  &__sso {
    display: flex;
    align-items: center;
    align-content: center;
    justify-content: center;
    gap: 1em;
    margin-top: 1em;
    padding-top: 1em;
    border-top: solid 1px #ccc;

    &__button {
      display: flex;
      justify-content: center;
    }
  }

  &__forget {
    margin-left: -10px;
    margin-top: 10px;
    font-size: var(--text-small);
  }
}

.microsoft-button,
.linkedin-button,
.gmail-button {
  background: var(--light-grey);
  color: black;
  border-radius: 6px;
  padding: 10px 10px 10px 10px;
  border: solid 1px var(--primary);

  svg {
    margin: 0 10px 0px 0;
    height: 24px;
  }

  .logo {
    margin: 0px 0px 0px 6px;
  }
}

// .linkedin-button {
//   background: #0077b5;
//   color: white;
// }
.logout-button {
  background: none;

  svg {
    margin-left: 5px;
  }
}

.microsoft-button:hover,
.gmail-button:hover,
.linkedin-button:hover {
  background: white;
}

// .linkedin-button:hover {
//   background-color: #017fc4;
// }

.login-button:hover {
  background: var(--dark2);
  border-color: #278fff;
}
