.categories {
  margin-top: 3em;

  &__header {
    .table__col {
      height: 70px;
      color: var(--primary);
      position: relative;
    }

    &__cell {
      position: absolute;
      left: 0;
      // right: 0;
      width: 90vw;
      top: 1.5em;
      border-radius: 3px;
      padding: .5em 1em;

      display: flex;
      gap: 1em;
      align-items: center;
    }
  }

  &__extend {
    width: 35px;
    height: 35px;
    // border: solid 1px var(--dark);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50vh;
    background: var(--primary);
    color: white;
    font-size: 1.5em;
  }
}
