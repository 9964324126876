.rte-editor .block-menu {
  transform: translateX(calc(-100% - 1em));
  position: absolute;
  z-index: 20;

  padding: .5em;
  border-radius: 5px;
  background: var(--lighter);
  box-shadow: 0 0 5px 0 var(--dark);
  pointer-events: all;

  display: flex;
  flex-direction: column;
  gap: .5em;

  &__list {
    li {
      background: var(--lightest);
      padding: .5em 1em;
      border-radius: 3px;
      margin: 3px;
      cursor: pointer;
    }
  }

  &__item {
    display: flex;
    align-items: center;
    gap: 1em;

    .dropdown__selected__label {
      white-space: nowrap;
    }
  }

  &__label {
    min-width: 50px;
  }

  &__btn {
    background: none;
    padding: .5em 0;
    color: var(--dark2);

    &:hover {
      color: black;
      cursor: pointer;
    }
  }

  .--parent {
    color: #999;
  }
}
