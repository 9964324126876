.my-signature {
  h4 {
    color: var(--dark);
    padding: 1em;
  }

  section {
    margin-top: 1em;
  }

  &__user {
    display: flex;
    gap: .5em;
    align-items: center;
    padding: 1em 0;

    &__name {
      flex: 1;
      font-family: 'Poppins', 'Segoe UI', 'Arial', sans-serif;
    }
  }

  &__actions {
    margin: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;

    .--label {
      display: flex;
      gap: .5em;
      align-items: center;
      padding: 1em .5em;

      svg {
        color: var(--dark-orange);
        // color: white;
        // padding: .25em 1em;
        border-radius: 50vh;
        box-sizing: content-box;
        height: 24px;
        width: auto;
      }

      &.--smart-id svg {
        color: var(--smart-id-color);
      }
    }
  }

  &__disclaimer {
    display: flex;
    align-items: center;
    padding: 1em;
    padding-bottom: 0;
    gap: 1em;
    color: var(--dark);
  }

  nav {
    display: flex;
    align-items: stretch;
    gap: .5em;

    button {
      background: var(--lightest);
      color: var(--dark2);
      border-radius: .5em;
      padding: .5em 1.5em;
      // height: 50px;
      border: solid 1px var(--grey);
      margin-bottom: -1px;
      position: relative;
      z-index: 1;

      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
      flex: 1;

      // .desc {
      //   color: var(--lighter);
      // }

      &.--smart-id {
        // background: var(--smart-id-color);
        // color: white;
        color: var(--smart-id-color);
        svg {
          height: 30px;
          width: 100px;
        }

        &.active {
          color: var(--smart-id-color);
          background: white;
        }
      }

      &:hover {
        background: white;
        color: var(--smart-id-color);
      }

      &.active {
        color: black;
        background: white;
        border-bottom-color: white;

        &:hover {
          background: white;
          cursor: default;
        }
      }

      &.activeTab {
        border-radius: .5em .5em 0 0;
      }
    }
  }

  article {
    border: solid 1px var(--grey);
    background: white;
    padding: 1em 0;
  }

  .offline-signature {
    background: none;
    border: none;
  }

  .offline-steps {
    // border: solid 1px red;
    // margin-left: 2em;
    position: relative;

    &:before {
      position: absolute;
      content: '';
      top: 15px;
      bottom: 15px;
      left: 37px;
      width: 2px;
      background: var(--dark);
    }

    & > li {
      // border: solid 1px orange;
      padding: .5em 1em;
      margin: .5em;
      position: relative;
      margin-left: 4em;
      display: flex;
      align-items: center;
      gap: 1em;
      color: var(--dark);

      .--step {
        position: absolute;
        display: flex;
        // content: attr(data-nr);
        width: 2em;
        height: 2em;
        // border: solid 1px red;
        align-items: center;
        justify-content: center;
        border-radius: 50vh;
        background: var(--dark);
        color: white;
        font-size: var(--text-big);
        font-style: normal;
        margin-left: -46px;

        &.--success {
          background: var(--success);
          // background: white;
          // border: solid 1px var(--success);
          // color: var(--success);
        }

        &.--error {
          background: var(--error);
        }
      }
    }

    &__info {
      flex: 1;
      h4 {
        padding: 1em 0;
      }
    }

    &__item {
      --item-bg: var(--cp-bg);
      margin: 1em 0;

      &__signature {
        display: flex;
        gap: .5em;
        align-items: center;
        // border: solid 1px var(--dark);
        // background: #b3141230;
        background: var(--item-bg);
        border-radius: 2px;
        min-height: 70px;
        padding: 0 1em;
      }

      &__disclaimer {
        // border: solid 1px red;
        margin-top: 3px;
        padding: 1em;
        background: var(--light-grey);
        border-radius: 2px;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }

      .--icon {
        color: var(--dark-orange);
        margin: 0 1em;
      }

      &.allowSign {
        --item-bg: var(--my-bg);

        .--icon {
          color: var(--success);
        }
      }

      &.conflict {
        --item-bg: #b3141230;
        .--icon {
          color: var(--error);
        }
      }

      &.missing {
        --item-bg: var(--grey);
      }

      .--signature {
        flex: 1;
      }

      .--title {
        font-size: var(--text-medium);
        font-weight: bold;
        // margin-bottom: .5em;
        display: flex;
        align-items: center;
        gap: 1em;
      }

      .--counterparty {
        color: var(--dark-orange);
      }

      .--contact {
        margin-top: .5em;
      }
    }
  }
}
