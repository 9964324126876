.position__signatures {
  // border: solid 1px orange;
  margin: .5em 1em;

  --row-direction: row;
  --row-align: flex-start;

  &.right {
    --row-direction: row-reverse;
    --row-align: flex-end;
  }

  .signature {
    padding: 0;
    border-radius: 5px;
    // border: solid 1px var(--lighter);
    color: var(--dark2);
    margin: .5em 0;

    display: flex;
    align-items: var(--row-align);
    font-size: var(--text);
    flex-direction: column;

    &.--waiting {
      background: var(--lightest);
      padding: 1px 0;
    }

    &__title {
      padding: 1em 0;
      display: flex;
      flex-direction: var(--row-direction);
      gap: 1em;
      position: relative;
      z-index: 1;

      svg {
        height: 16px;
        width: 16px;
      }
    }

    &__label {
      background: #a9b6c2;
      color: white;
      padding: 0 1em;
      border-radius: 50vh;

      &.--signed {
        background: var(--success);
      }
    }

    &__preview {
      position: relative;
      background: white;
      padding: 0;
      border-radius: 5px;
      margin: 0;
      min-width: 250px;
      // border: solid 1px red;
      border-radius: 5px;
      overflow: hidden;
      margin-top: -8px;
      z-index: -1;

      .digital-signature {
        border: none;
      }
    }
  }
}
