.progress-circle {
  display: flex;
  align-items: center;
  gap: 0.5em;

  --width: 45px;
  --height: 45px;
  --width: 18;

  --r: 65;
  --p: calc(3.14 * 2 * var(--r) * 1px);
  --percents: 100;
  --dashoffset: calc(var(--p) - (var(--p) * var(--percents)) / 100);
  --dashoffsetFull: calc(var(--p) - (var(--p) * 1) / 100);
  --circle-color: var(--inviting);

  &.warning {
    --circle-color: var(--dark-orange);
  }

  &.success {
    --circle-color: var(--success);
  }

  &.error {
    --circle-color: var(--error);
  }

  &__chart {
    position: relative;
    width: var(--width);
    height: var(--height);
    border-radius: 50%;

    z-index: 10;

    svg {
      position: relative;
      width: var(--width);
      height: var(--height);
      z-index: 10;
      transform: rotate(-90deg);
    }

    svg circle {
      width: 100%;
      height: 100%;
      fill: none;
      stroke: var(--circle-color);
      stroke-width: var(--width);
      stroke-linecap: round;
      stroke-dasharray: var(--p);
      stroke-dashoffset: var(--dashoffset);

      transition: stroke-dashoffset 0.3s ease-in-out;
      animation: rotateIn 1s ease-in-out;

      &.bg {
        stroke-dashoffset: 0;
        stroke: #ffffff22;
        animation: none;
      }
    }

    &__center {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      border-radius: 50%;

      h2 {
        // color: var(--primary);
        font-weight: 300;
        color: white;
        font-size: 11px;
        transition: 0.5s;
      }
    }
  }

  @keyframes rotateIn {
    from {
      stroke-dashoffset: var(--p);
    }
  }
}
