.smart-id {
  &__register {
    // padding: 0 1em;

    h4 {
      padding: 1em;
    }

    .--label {
      display: flex;
      gap: 1em;
      align-items: center;
    }

    .--smart-id svg {
      color: var(--smart-id-color);
    }

    .smart-id {
      display: flex;
      align-items: center;
      justify-content: space-between;
      // margin: 1em .5em;

      &__actions {
        padding: 1em .5em;
      }

      &__pending {
        color: var(--dark2);
        // padding: 1em 0;

        h1 {
          color: var(--dark);
          margin: 1em 0;
          display: inline-flex;
          font-size: var(--text-big);
          background: var(--grey);
          border: solid 1px var(--dark);
          padding: 0.5em 1em;
          border-radius: 3px;
        }
      }

      .error,
      .success {
        padding: 1em 2em;
        margin: 1em 0;
        font-size: var(--text-medium);
        background: var(--error2);
        color: white;
        border-radius: 5px;
      }

      .success {
        background: var(--success);
      }
    }
  }

  .--label {
    display: flex;
    align-items: center;
    gap: 1em;
  }

  .--smart-id svg {
    color: var(--smart-id-color);
  }
}

.smart-id,
.sign__pending {
  .ECONNABORTED,
  .TIMEOUT {
    background: #ffb86f87;
    padding: .5em 2em;

    h4 {
      color: var(--darker);
      button {
        display: block;
      }
    }
  }

  .FAILED,
  .NOT_FOUND,
  .SIGNED_BY_OTHER,
  .WRONG_VC,
  .USER_REFUSED,
  .USER_REFUSED_DISPLAYTEXTANDPIN,
  .USER_REFUSED_CONFIRMATIONMESSAGE {
    background: #e8533d87;
    padding: .5em 2em;

    h4 {
      color: var(--darker);
      button {
        display: block;
      }
    }
  }
  .SIGNED_BY_OTHER h4 button {
    display: none;
  }

  .OK,
  .SIGNED {
    background: #73ccac87;
    padding: .5em 2em;
  }
}

.elections .smart-id__register {
  .form-item {
    max-width: 500px;
  }

  .form-input {
    margin: 0;
  }

  .form-group__children {
    margin: 5px 10px;
  }
}

.sign {
  &__pending {
    padding: 0 1em;
    border: solid 1px red;

    .--preview {
      margin: 0 .5em;
    }
  }

  &__content {
    margin: 0 1em;
    padding: .5em;
    border-radius: 5px;

    h4 {
      padding: 1em 0;
      display: flex;
      align-items: center;
      justify-content: space-between;

      button {
        display: none;
      }
    }

    .--code {
      background: var(--grey);
      padding: .75rem 1.5rem;
      font-size: var(--text-big);
      display: inline-flex;
      border-radius: 3px;
      font-weight: 600;
      letter-spacing: 3px;
      color: var(--dark);
      border: solid 1px var(--dark);
    }
  }

  &__list-item {
    display: flex;
    align-items: center;
    gap: 1em;
    margin: .5em 0;

    label {
      color: var(--dark);
    }
  }
}
