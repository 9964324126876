.form-select {
  .read-only,
  .empty {
    color: #999;
  }

  option {
    color: black;
  }

  .dropdown__selected__label {
    color: black;
  }

  &.value {
    .dropdown__selected__label {
      background: var(--my-bg);
      border-color: var(--my-border);
    }
  }

  &.matched {
    .dropdown__selected__label {
      background: var(--matched-bg);
      border-color: var(--matched-border);
    }
  }
}
