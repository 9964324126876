.elections {
  --my-bg: #e9f1f9;
  --my-border: #2086c445;
  --my-color: var(--medium);

  --matched-bg: var(--light-green);
  --matched-border2: var(--matched-border);
  --matched-border-size: 1px;

  .isRestricted,
  .notEditable {
    border-radius: 2px;
    --bg-shadow: var(--error);
    --bg-color: none;
    --icon-color: var(--medium);

    &.isHidden {
      --bg-color: #e9e8e591;
      --icon-color: var(--darker);

      .form-input__view-only {
        background: var(--dark2);
        color: white;
      }
    }

    &.isCompleted {
      --bg-shadow: var(--success);
      --icon-color: var(--success);
    }

    &.viewOnly {
      --bg-shadow: none;
      --bg-color: none;

      --icon-color: transparent;
    }

    .form-item {
      background: var(--bg-color);
      box-shadow: 0 0 0px 2px white, 0 0 2px 3px var(--bg-shadow);
      border-radius: 1px;
    }

    &__icon {
      color: var(--icon-color);
      i {
        display: flex;
      }
    }
  }

  &.isOwner {
    .viewOnly {
      --icon-color: var(--dark);
    }
  }

  .form {
    &-label {
      padding: .5em;
      font-size: var(--text);
      font-weight: 500;
      color: var(--dark);

      .form-actions {
        display: flex;

        .--more {
          display: flex;
        }
      }
    }

    &-item {
      padding: .5em;

      &:hover {
        .--more {
          .dropdown__selected {
            visibility: visible;
          }
        }
      }

      &.readOnly {
        --my-bg: var(--my-border);
        --matched-bg: #d0e6d1;

        input {
          border: none;

          --matched-border-size: 0;
        }

        &.empty,
        .empty {
          &.textarea,
          textarea,
          input {
            border: solid 1px var(--warning);
          }

          &.cpOnly {
            background: none;
          }
        }
      }

      &.cpOnly {
        opacity: 0.5 !important;
        animation: none;
        transition: 0.3s;
      }
    }

    &-input {
      padding: 0;
      // margin-left: 2.25em;

      .prefix {
        margin-right: 10px;

        &.single {
          font-size: var(--text-medium);
          padding-left: 5px;
          display: flex;
          align-items: center;
        }
      }

      .suffix {
        margin-left: 10px;
      }

      [contenteditable],
      .textarea,
      textarea,
      select,
      input {
        // padding-left: 15px;

        &.empty {
          background: white;
        }

        &.value {
          background: var(--my-bg);
          border-color: var(--my-border);
        }

        &.matched {
          border-color: var(--matched-border2);
          border-left: solid var(--matched-border-size) var(--matched-border);

          background: var(--matched-bg);
        }
      }

      &__value {
        &.value {
          background: var(--my-bg);
          border-color: var(--my-border);
          border: solid 1px var(--read-only-border);
        }
        &.matched {
          border-left: solid var(--matched-border-size) var(--matched-border);

          background: var(--matched-bg);
        }
        &.empty {
          border: solid 1px var(--read-only-border);
        }
      }
    }

    &__input {
      &__value {
        flex: 1;
        padding: 1em;
        border-radius: 0.5em;
        background: var(--light-blue);

        &.empty {
          background: none;

          &:before {
            content: '-- empty --';
            color: #ccc;
          }
        }
      }
    }
  }

  .radio {
    &__item {
      min-width: 200px;
    }
  }
}

.changed-only {
  [input-name] {
    .form-item {
      opacity: 0.3;
    }
    &.isChanged {
      .form-item {
        opacity: 1;
        border-radius: .5em;
        box-shadow: 0 0 15px var(--dark);
        background: var(--light-grey);
      }
    }
  }
}

.incompleted-only {
  [input-name] {
    .form-item {
      opacity: 0.3;
    }
    &.isIncompleted {
      .form-item {
        opacity: 1;
        border-radius: .5em;
        box-shadow: 0 0 15px var(--dark);
        background: var(--light-grey);
      }
    }
  }
}

.completed-only {
  [input-name] {
    .form-item {
      opacity: 0.3;
    }
    &.isCompleted {
      .form-item {
        opacity: 1;
        border-radius: .5em;
        box-shadow: 0 0 15px var(--dark);
        background: var(--light-grey);
      }
    }
  }
}

.unmatched-only {
  [input-name] {
    .form-item {
      opacity: 0.3;
    }
    &.isUnmatched {
      .form-item {
        opacity: 1;
        border-radius: .5em;
        box-shadow: 0 0 15px var(--dark);
        background: var(--light-grey);
      }
    }
  }
}

.matched-only {
  [input-name] {
    .form-item {
      opacity: 0.3;
    }
    &.isMatched {
      .form-item {
        opacity: 1;
        border-radius: .5em;
        box-shadow: 0 0 15px var(--dark);
        background: var(--light-grey);
      }
    }
  }
}

.error-only {
  [input-name] {
    .form-item {
      opacity: 0.3;
    }
    &.isInvalid {
      .form-item {
        opacity: 1;
        border-radius: .5em;
        box-shadow: 0 0 15px var(--dark);
        background: var(--light-grey);
      }
    }
  }
}

.error-only,
.changed-only,
.incompleted-only,
.unmatched-only {
  [input-name] {
    .form-item {
      opacity: 0.3;
    }
    .form-item:has(:focus) {
      opacity: 1;
      border-radius: .5em;
      box-shadow: 0 0 15px var(--dark);
      background: var(--lightest);
    }
  }
}

ins {
  background: #b8e5e7;
}

del {
  background: #fab4b3;
}
