.search-table {
  --table-bg: white;
  --table-selected-bg: var(--cp-bg);
  --table-content-bg: var(--lightest);
  --table-add-btn: var(--darker);
  --table-add-bg: #fffffff2;
  --table-icon-color: var(--medium);
  --table-header-color: black;
  --table-header-font: var(--text-medium);
  --table-head-font: var(--text-big);
  --cell-padding: 5px;
  --table-row-margin: 0.25em;
  --table-radius: 2px;
  position: relative;

  &.light {
    --table-header-color: white;
    --table-add-btn: var(--medium);
    --table-add-bg: var(--grey);
    --table-content-bg: var(--medium);
    --table-bg: var(--lightest);
    --table-header-font: var(--text-small);
    --table-head-font: var(--text);
    --table-header-color: var(--dark);
  }

  &.dark {
    --table-header-color: white;
    --table-add-btn: var(--lightest);
    --table-content-bg: #f6fafd;
  }

  &.darker {
    --table-header-color: white;
    --table-add-btn: var(--link);
    --table-content-bg: #adc0d57d;
    --table-bg: var(--lighter);

    .desc {
      color: var(--dark);
    }
  }

  &.white {
    --table-content-bg: #fffffff2;
  }

  &.small {
    --table-header-font: var(--text-small);
    --cell-padding: 0 5px;
  }

  &.secondary {
    --table-add-bg: #ffffff33;
    --table-bg: var(--lightest);
    --table-header-color: white;
    --table-add-btn: var(--lightest);
    .desc {
      color: white;
    }
  }

  &.selectable {
    --table-bg: var(--lighter);
    --table-selected-bg: white;
    --table-icon-color: var(--success);

    .desc {
      color: var(--dark);
    }
  }

  display: flex;
  align-items: flex-start;

  article {
    flex: 1;
    position: relative;
  }
}

.table {
  display: table;

  flex: 1;
  width: 100%;
  border-spacing: 0;
  color: black;
  height: 100%;

  a.table__row:hover .cell {
    --table-bg: var(--lightest);
  }

  &__tbody {
    display: table-row-group;
  }

  &__row {
    display: table-row;
    text-align: left;
    position: relative;

    &.my-turn {
      font-weight: bold;
    }

    &.cp-turn {
      --table-bg: var(--light-blue);
    }

    &.clickable {
      cursor: pointer;
      &:hover {
        --table-bg: var(--grey);
      }
    }

    &.active,
    &.selected {
      --table-bg: var(--table-selected-bg);
    }

    &.added {
      animation: fadeIn 0.3s reverse;
    }

    &.clickable:hover .cell {
      --table-bg: var(--light-grey);
    }
  }

  a.table__row {
    position: relative;

    & > div:first-child:before {
      content: '';
      position: absolute;
      top: 5px;
      left: 0;
      right: 0;
      bottom: 0;
      // background-color: #000000bb;
      box-shadow: 0 0 5px -2px var(--dark);
    }
  }

  &__head {
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    margin: 0 0.5em 1em;
    color: var(--table-header-color);
    font-size: var(--table-head-font);

    label {
      padding: 0.5em 0;
      display: block;
    }
  }

  &__col {
    display: table-cell;
    padding: 0;
    padding-top: var(--table-row-margin);
    vertical-align: middle;
    white-space: pre-wrap;
    z-index: 1;
    height: 100%;

    .cell {
      background: var(--table-bg);
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: stretch;
      justify-content: center;

      padding: var(--cell-padding);

      .description {
        margin-top: 5px;
        color: #999;
        font-size: var(--text-small);
        font-weight: normal;
      }
    }

    &:first-child > .cell {
      padding-left: 10px;
      border-top-left-radius: var(--table-radius);
      border-bottom-left-radius: var(--table-radius);
    }

    &:last-child > .cell {
      border-top-right-radius: var(--table-radius);
      border-bottom-right-radius: var(--table-radius);
    }
  }

  &__header {
    background: none;
    color: var(--table-header-color);
    font-size: var(--table-header-font);
    font-weight: normal;
    white-space: nowrap;
    padding: 0.5em;
    // padding-bottom: .5em;
    z-index: 9;

    .table__col {
      background: none;
      padding: 5px;
      position: sticky;
      top: 65px;
      padding-bottom: 2em;

      .--sort-header {
        display: flex;
        align-items: center;
        gap: 0.5em;
        cursor: pointer;

        &:hover {
          text-decoration: underline;
        }

        svg {
          height: 16px;
          width: 16px;
        }

        button {
          background: none;
        }

        &.right {
          justify-content: flex-end;
        }

        &.center {
          justify-content: center;
        }
      }

      &:before {
        content: none;
      }
    }
  }

  .table__expand {
    border-radius: 0;
    padding: 0;
    background: none;
    z-index: 0;

    &:before {
      content: none;
    }

    &__btn {
      width: 30px;

      button {
        border-radius: 50vh;
        padding: 0;
        height: 24px;
        width: 24px;
        background: var(--lighter);
        transition: 0.3s;
      }

      &.open button {
        background: var(--dark2);
        transform: rotate(90deg);
      }
    }
  }

  &__btn {
    background: none;
    color: var(--lighter);
    padding: 5px 0;
  }

  &__content {
    position: relative;
    margin-top: -2px;
    margin-bottom: 5px;
    border-bottom-left-radius: var(--table-radius);
    border-bottom-right-radius: var(--table-radius);
    background: var(--table-content-bg);
    padding: 10px;
    min-height: 60px;

    &__header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding-right: 10px;
    }

    &__flex {
      display: flex;
      justify-content: space-between;
      align-items: center;
      gap: 10px;
    }

    &__info {
      li {
        padding: 5px 10px;
      }
    }
  }

  &__confirm {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    padding-top: 10px;
    background: #000000bb;
    border-bottom-left-radius: var(--table-radius);
    border-bottom-right-radius: var(--table-radius);
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 10px;
    color: white;
    z-index: 11;

    &__buttons {
      display: flex;
      align-items: center;
    }
  }

  &__add {
    margin-bottom: 5px;
    border-radius: var(--table-radius);
    background: var(--table-add-bg);

    &__btn.btn.link {
      font-size: var(--table-header-font);
      color: var(--table-add-btn);
    }

    &__btn.btn {
      background: none;
      // padding: .25em 1em .25em .5em;
      border: none;
      background: var(--success-dark);
      color: white;
    }

    &__header {
      background: var(--lightest);
      padding: 10px 20px;
      border-radius: var(--table-radius);
    }

    &__content {
      padding: 10px;
      display: flex;

      & > div {
        flex: 1;
        gap: 20px;
      }
    }

    &__actions {
      display: flex;
      align-items: center;
      padding-top: 10px;
      color: var(--dark);
      gap: 0.5em;
    }
  }

  &__center {
    text-align: center;
  }

  &__state {
    width: 56px;
  }

  //--------------------------------------------------------------
  //  Inner style
  //--------------------------------------------------------------
  &__icon {
    display: inline-flex;
    color: var(--table-icon-color);
    padding: 3px 5px;
    border-radius: 5px;
    font-size: var(--text-big);
    font-weight: bold;
    min-width: 30px;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__logo {
    display: inline-flex;
    background: var(--medium);
    color: white;
    padding: 3px 5px;
    border-radius: 5px;
    font-size: var(--text-big);
    font-weight: bold;
    min-width: 30px;
    align-items: center;
    justify-content: center;

    svg {
      width: 24px;
      height: 24px;
    }
  }

  &__title {
    width: 100%;
    padding: 5px 0;
    white-space: normal;
  }

  &__date {
    white-space: nowrap;
    display: flex;
    align-items: center;
    height: 100%;
    position: relative;

    .date-updated {
      font-weight: normal;
      font-size: var(--text-small);
    }
  }

  &__party {
    text-align: right;
    white-space: nowrap;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;

    svg {
      margin-right: 10px;
      color: var(--lighter);
    }

    &.--cp {
      text-align: left;
      justify-content: flex-start;
      svg {
        color: var(--dark);
      }
    }

    .position {
      font-size: var(--text-small);
      color: var(--medium);

      &.position-index-1 {
        color: var(--primary-btn);
      }
    }
  }

  &__actions {
    .disabled {
      opacity: 0.1;
    }
  }
}

.sender-icon {
  padding: 0;
  width: 24px;
  color: var(--dark-orange);
  text-align: center;
  padding-right: 4px;

  &.sender {
    color: var(--success);
  }
}
