.tiles2 {
  display: flex;
  max-width: 1300px;
  margin: 30px auto;
  flex-wrap: wrap;
  justify-content: space-evenly;

  .tile {
    position: relative;
    padding: 15px;
    border-radius: 10px;
    text-align: center;
    margin: 20px;
    width: 220px;
    height: 220px;
    cursor: pointer;
    background: var(--dark);
    box-shadow: 2px 2px 5px #ccc;
    transition: 0.3s;
    display: flex;
    flex-direction: column;
    gap: .5em;
    color: white;

    &:hover {
      box-shadow: 2px 2px 5px var(--medium);
      background: #227396;

      svg {
        transform: rotate(10deg) scale(1.2, 1.2);
        transition: .3s;
        // color: red;
      }
    }

    &[disabled] {
      opacity: .5;
      user-select: none;
      pointer-events: none;
    }

    &__title {
      font-size: 24px;
      a {
        justify-content: center;
        &::before {
          content: '';
          position: absolute;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          border-radius: 50px;
        }
      }
    }

    &__icon svg {
      width: 60px;
      height: 60px;
    }

    &__desc {
      color: var(--lighter);
      line-height: 1.5;
    }

    &__count {
      position: absolute;
      right: 30px;
      top: 40px;
      display: flex;
      align-items: center;
      justify-content: center;

      .--is-new {
        padding: 5px 10px;
        border-radius: 50vh;
        position: absolute;
        bottom: 20px;
        left: 50%;
        background: var(--dark-orange);
      }

      .--total {
        font-size: var(--text-small);
        background: var(--primary);
        position: relative;
        border-radius: 50vh;
        padding: 5px;
        min-width: 30px;
        z-index: 0;
      }
    }
  }
}

.tiles {
  padding: .5em 0;

  .tile {
    // border: solid 1px var(--medium);
    display: flex;
    gap: 1em;
    align-items: center;

    padding: 1em;
    margin: 2em 1em;
    color: white;
    border-radius: .5em;
    box-shadow: 0 0 10px 0 var(--darker);
    background: var(--darker);
    cursor: pointer;
    position: relative;

    &:hover {
      box-shadow: 0 0 10px 0 #ccc;
    }

    &__icon {
      svg {
        display: flex;
      }
    }

    &__title {
      font-size: 18px;
      font-weight: 500;
    }

    &__desc {
      font-weight: 300;
      color: var(--light-blue);
    }

    &__count {
      position: absolute;
      right: 30px;
      top: 33px;

      .--is-new {
        display: flex;
        align-items: center;
        justify-content: center;

        padding: 5px 10px;
        border-radius: 50vh;
        position: absolute;
        bottom: 20px;
        left: 50%;
        background: var(--dark-orange);
      }

      .--total {
        display: flex;
        align-items: center;
        justify-content: center;

        font-size: var(--text-small);
        background: var(--primary);
        position: relative;
        border-radius: 50vh;
        padding: 5px;
        min-width: 30px;
        z-index: 0;
      }
    }

    a {
      // border: solid 1px red;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }
    }
  }
}
