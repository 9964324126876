.db {
  display: flex;
  flex-wrap: wrap;

  &__collection {
    border: solid 1px var(--dark);
    // padding: 10px;
    border-radius: 10px;
    margin: 10px;
    min-width: 300px;
    background: var(--lightest);

    header {
      padding: 5px 10px;
      font-size: var(--text-medium);
      background: var(--dark);
      color: white;
      border-radius: 8px;
      display: flex;
      justify-content: space-between;
    }

    ul {
      padding-bottom: 10px;
    }
    li {
      margin: 5px 20px;
      display: flex;
      align-items: center;
      font-family: monospace;
    }

    svg {
      height: 16px;
      width: 16px;
      margin: 0 5px;
    }

    h4 {
      padding: 10px 10px 5px;
    }

    .--to,
    .--from {
      color: var(--primary-btn);
    }

    .--me {
      color: var(--dark-orange);
    }
  }

  &__chart {
    min-height: 800px;
  }
}
