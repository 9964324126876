/* Add space around the hr to make clicking it easier */
.rte-editor {
  width: 100%;
  margin-top: 20px;
  position: relative;

  &.value .ProseMirror {
    background: var(--my-bg);
    border-color: var(--my-border);
  }

  &.matched .ProseMirror {
    border-color: var(--matched-border);
    border-left: solid 5px var(--matched-border);
    padding-left: 10px;

    background: var(--light-green);
  }

  .ProseMirror {
    background: white;
    // font-family: arial;
  }

  .ProseMirror div {
    outline: solid 1px #eee;
  }

  pm-block {
    --ol-hover: #ccc;

    ol ol {
      --ol-hover: var(--warning);
    }

    ol ol ol {
      --ol-hover: var(--lighter);
    }

    ol ol ol ol {
      --ol-hover: var(--success);
    }

    ol:hover {
      outline: dashed 1px var(--ol-hover);
    }
  }

  [dr-each] {
    background: aliceblue;
    & > td {
      position: relative;
      --label-color: var(--inviting);
      &:before {
        content: 'Each';
        background: var(--label-color);
        position: absolute;
        left: -2px;
        top: -2px;
        border-radius: 3px;
        font-size: 11px;
        padding: 0 0.5em;
        color: white;
      }
    }
  }

  [dr-when] {
    position: relative;
    &::after {
      content: attr(dr-value) ' = ' attr(dr-when);
      position: absolute;
      background: var(--orange);
      color: var(--dark2);
      border-radius: 3px;
      font-size: 11px;
      font-family: monospace;
      padding: 0 0.5em;
      z-index: 1;
      top: -5px;
      left: -5px;
      right: auto;
      bottom: auto;
    }
  }
}
