.signature-styles {
  // border: solid 1px red;
  max-width: 500px;
  padding: 1em 0;
  margin: 0 1em;
  position: relative;
  // z-index: 101;

  &__selected {
    border: solid 1px orange;
    height: 60px;
    border-radius: 5px;
    background: white;

    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;

    background: var(--my-bg);
    border-color: var(--my-border);
    position: relative;
  }

  &__icon {
    position: absolute;
    right: 10px;
    top: 14px;
  }

  &__list {
    margin-top: 10px;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 100%;
    opacity: 0;
    z-index: 20;
    pointer-events: none;

    &.open {
      opacity: 1;
      pointer-events: all;
    }

    li {
      border: solid 1px var(--grey);
      display: flex;
      justify-content: center;
      align-items: center;
      height: 60px;
      border-radius: 2px;
      background: white;
      cursor: pointer;

      &:hover {
        background: var(--grey);
      }
      // place-items: center;
    }
  }
}
