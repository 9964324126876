.date-picker .pick {
  display: flex;

  &__year {
    margin: 5px 0;

    ul {
      background: var(--lightest);
      li {
        width: 70px;
        border-radius: 50vh;
        margin: 3px 10px;
        padding: 6px 10px;
      }
    }

    .up,
    .down {
      background: linear-gradient(to top, var(--lightest), white);
      padding: 0;
      display: flex;
      justify-content: center;
      user-select: none;
    }

    .down {
      background: linear-gradient(to bottom, var(--lightest), white);
    }
  }

  &__month {
    flex: 1;
    margin: 20px 10px 0px;

    ul {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 5px;
      grid-row-gap: 15px;

      li {
        padding: 15px 5px;
        border-radius: 50vh;
        color: black;
        font-size: var(--text);
      }
    }
  }

  li {
    text-align: center;
    padding: 10px;
    font-size: var(--text-medium);
    cursor: pointer;
    user-select: none;

    &:hover {
      background: var(--grey);
    }
  }

  .active {
    background: white;
    color: black;
  }

  .selected {
    background: var(--dark);
    color: white;

    &:hover {
      background: var(--dark2);
    }
  }

  &__actions {
    display: flex;
    justify-content: flex-end;
    margin: 5px 0;
  }
}
