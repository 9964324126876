.doc-logo {
  padding: 15px;
  padding-bottom: 5px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;

  svg {
    width: 60px;
    height: 60px;
  }

  &__label {
    font-size: var(--text-medium);
    text-align: right;
  }
}
