@font-face {
  font-family: 'icomoon';
  src: url('fonts/icomoon.eot?b9fwpz');
  src: url('fonts/icomoon.eot?b9fwpz#iefix') format('embedded-opentype'),
    url('fonts/icomoon.ttf?b9fwpz') format('truetype'), url('fonts/icomoon.woff?b9fwpz') format('woff'),
    url('fonts/icomoon.svg?b9fwpz#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

.document-edit__content {
  --icon-warning: "\e900";
  --icon-textarea: "\e901";
  --icon-text: "\e902";
  --icon-radio: "\e903";
  --icon-calendar: "\e905";

  // --icon: var(--icon-textarea);

  [dr-text] {
    padding-right: 3px;

    &[dr-format^="date"] {
      --icon: var(--icon-calendar);
    }

    &[dr-format="toWords"] {
      --icon: 'nr to words';
      &:after {
        font-family: Arial, Helvetica, sans-serif;
        font-size: .8em;
        height: 1.3em;
      }
    }

    &[dr-format="multiline"] {
      --icon: 'multiline';
      &:after {
        font-family: Arial, Helvetica, sans-serif;
        font-size: .8em;
        height: 1.3em;
      }
    }

    &[dr-format="numberDE"],
    &[dr-format="number"] {
      --icon: 'number';
      &:after {
        font-family: Arial, Helvetica, sans-serif;
        font-size: .8em;
        height: 1.3em;
      }
    }

    &:after {
      font-family: 'icomoon';
      speak: never;
      font-style: normal;
      font-weight: normal;
      font-variant: normal;
      text-transform: none;
      line-height: 1;

      /* Better Font Rendering =========== */
      -webkit-font-smoothing: antialiased;
      -moz-osx-font-smoothing: grayscale;

      // position: absolute;
      box-sizing: border-box;
      // right: 5px;
      border-radius: 2px;
      content: var(--icon);
      margin: 0 -2px 0 .25em;
      padding: 0 3px;
      color: var(--dark);
      background: white;
      font-size: 1em;

      height: 100%;
      // display: flex;
      align-items: center;
    }
  }
}
