.admin {
  display: flex;
  height: 100%;
  background: var(--dark);

  h1 {
    margin: 15px;
    display: flex;
    align-items: center;
    gap: 10px;

    svg {
      color: var(--lighter);
    }
  }

  &__nav {
    width: 200px;
    display: flex;
    flex-direction: column;
    font-size: var(--text-medium);
    margin-bottom: 20px;
    // background: rgba(var(--darker-rgb), .75);
    // background: var(--matched-border);
    background: var(--darker);
    border-bottom-right-radius: 18px;
    padding-top: 20px;
    color: white;

    &__item {
      padding: 20px;
      position: relative;
      margin: 5px 0;

      a:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
      }

      &:hover {
        background: var(--dark);
      }
      &.active {
        background: var(--lighter);
        color: black;
      }
    }
  }
}

.sig {
  border: solid 1px blue;
  background: white;
  box-shadow: 10px 10px 15px 2px #999;
}

.sig__image {
  border-top: dotted 3px red;
  border-radius: 15px;
  padding: 10px;
}

.box {
  background: #e7e9eb;
  color: black;
  padding: 20px;
  border-radius: 5px;
}

.box__header {
  font-size: 30px;
}

.box__content {
  // border-left-color: green;
  // border-left-width: 5px;
  // border-left-style: solid;

  border-left: green solid 5px;
  padding: 10px;
  margin-top: 20px;
  margin-bottom: 20px;
  background: white;
}

.box__actions button {
  padding: 10px 20px;
  background: #04aa6d;
  font-size: 19px;
}
