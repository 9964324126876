.form-item .checkbox {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;

  --compare-border: orange;
  --compare-bg: var(--cp-bg);
  --compare-text: black;

  .readOnly {
    padding-left: 1em;
    color: #aaa;

    svg {
      display: none;
    }

    .tag {
      top: 8px;
    }

    &.selected {
      color: black;
    }
  }

  .disabled {
    color: #aaa;

    &.checkbox__item {
      &.selected {
        background: var(--grey);
        border-color: #ccc;
        svg {
          color: var(--grey);
          background: #ccc;
          border-radius: 2px;
        }
      }
      svg {
        color: #666;
      }
    }

    .tag {
      top: 8px;
    }

    &.selected {
      color: black;
    }
  }

  &__item {
    position: relative;
    display: flex;
    align-items: center;
    border: solid 1px transparent;
    padding: .25em 0.5em;
    padding-right: 1em;
    border-radius: 3px;
    margin: .25em;
    cursor: pointer;
    min-width: 250px;
    width: 100%;
    color: var(--compare-text);

    &:first-child {
      margin-top: 0;
    }

    &:focus {
      outline: none;
      box-shadow: 0 0 5px #ccc;
    }

    svg {
      margin-right: 10px;
      flex-shrink: 0;
    }

    &.selected {
      border-color: var(--my-border);
      background: var(--my-bg);
      svg {
        color: var(--my-color);
      }
    }

    &.cpPosition {
      // border-color: var(--compare-border);
      background: var(--compare-bg);

      &.main {
        --compare-border: var(--secondary);
        --compare-bg: var(--color3);
      }

      &.version {
        --compare-border: var(--secondary);
        --compare-bg: var(--medium);
        --compare-text: white;
      }
    }

    &.cpPosition.selected {
      border-color: var(--matched-border);
      background: var(--matched-bg);
      border-left: solid var(--matched-border-size) var(--matched-border);
      color: black;
      svg {
        color: var(--secondary);
      }

      .tag {
        display: none;
      }
    }
  }

  hr {
    border: solid 1px var(--grey);
    width: 100%;
  }

  .tag {
    position: absolute;
    top: 10px;
    right: -18px;
    font-size: 8.5px;
    font-weight: bold;
    display: flex;
    letter-spacing: 1px;

    &__primary,
    &__compare {
      border: solid 1px var(--primary);
      border-radius: 50vh;
      padding: 0 5px;
      background: var(--color2);
      margin: 0 3px;
    }

    &__primary {
      display: none;
    }
    &__compare {
      background: white;
      color: black;
      border: solid 2px var(--compare-bg);
    }
  }
}
