.negotiation {
  &.negotiation {
    background: none;
  }

  .doc-style {
    box-shadow: 0 0 5px 0 #ccc;
  }

  .help-link {
    display: flex;
    align-items: center;
    gap: 0.5em;
    justify-content: flex-end;
    color: var(--primary);
    text-decoration: underline;
    margin: 0.5em;
    cursor: pointer;

    svg {
      color: var(--primary);
    }
  }

  .help-box {
    padding: 1em;
    border-radius: 5px;
    margin: 1em;
    background: var(--light-blue);
    display: flex;
    justify-content: space-between;
    border: solid 1px var(--lighter);
    animation: fadeIn 0.5s ease-in-out;
    opacity: 1;

    svg {
      flex-shrink: 0;
      color: var(--primary);
    }

    &.disabled {
      background: var(--grey);
      border-color: var(--grey-medium);
    }

    &__list {
      counter-reset: tip;

      li {
        counter-increment: tip;

        display: flex;
        margin: 1em 0;
        align-items: flex-start;
        gap: 1em;

        &:before {
          content: 'Tip ' counter(tip) '. ' !important;
          color: var(--primary);
          width: 45px;
          flex-shrink: 0;
          // font-weight: bold;
          background: white;
          border-radius: 50vh;
          display: flex;
          align-items: center;
          justify-content: center;
          font-size: var(--text-small);
        }
      }
    }

    &__actions {
      display: flex;
      justify-content: flex-end;
    }
  }
}

.toolbars {
  height: 50px;
  background: var(--primary);
  // background: var(--grey-dark);
  color: white;
  position: sticky;
  top: 60px;
  z-index: 11;
  border-radius: 50vh;
  padding: 0.5em;
  padding-left: 1.5em;
  margin: 1em 0;
  // box-shadow: 0 0 5px var(--lighter);

  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 1em;

  .help-btn {
    background: none;
    border-radius: 50vh;
    padding: 0.5em;
    svg {
      margin: 0;
    }
  }

  &.completed {
    // background: var(--dark);
    background: var(--light-blue);
    color: var(--dark2);
    border: solid 1px var(--lighter);
    display: none;

    .toolbars__nr {
      // background: var(--primary);
      // background: var(--dark);
      // color: var(--grey);
      border: solid 1px var(--grey);
    }

    .help-btn {
      color: var(--dark);
    }
  }

  &.disabled {
    background: var(--grey-dark);
    .toolbars__nr {
      background: var(--grey-dark);
      color: white;
    }
  }

  &__nr {
    font-size: 32px;
    width: 42px;
    background: var(--lightest);
    height: 42px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50vh;
    color: var(--primary);
    margin-right: 0.5em;
  }

  &__content {
    flex: 1;

    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 2em;

    .desc {
      color: var(--lighter);
    }

    .isDraft {
      color: var(--warning);
    }

    .version {
      background: white;
      padding: 0 0.5em;
      margin-left: 1em;
      border-radius: 50vh;
    }
  }

  &__stats {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1.5em;

    &__tab {
      padding: 0 0.5em;
      border-radius: 3px;
      cursor: pointer;
    }

    .active {
      background: var(--dark2);
    }

    &__done {
      display: flex;
      align-items: center;
      gap: 0.5em;

      svg {
        color: var(--success);
      }
    }
  }

  &__info {
    display: flex;
    align-items: center;
    gap: 0.5em;

    .info-list {
      display: flex;
      flex-direction: column;
      align-items: center;
      padding: 3px 0.5em 0;
      border-radius: 3px;

      --nr-bg: var(--lighter);
      font-size: var(--text-small);

      span {
        width: 24px;
        height: 24px;
        font-size: 15px;
        border-radius: 50vh;
        background: var(--nr-bg);
        color: black;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      &.incompleted {
        --nr-bg: var(--warning);
      }

      &.unmatched {
        --nr-bg: var(--grey);
        span {
          color: var(--dark-orange);
        }
      }

      &.error {
        --nr-bg: var(--error2);
        color: #eed3d0;
        span {
          color: white;
        }
      }

      &.changed {
        --nr-bg: var(--link);
        span {
          color: white;
        }
      }
    }
  }

  &__actions {
    .waiting {
      // color: var(--cp-bg);
      color: white;
      background: var(--warning);
      padding: 3px 1em;
      border-radius: 50vh;
      text-transform: uppercase;
      // font-weight: bold;
      font-size: var(--text-medium);
    }
  }

  &__state {
    text-transform: uppercase;
    background: var(--active);
    color: white;
    padding: 0 1em;
    border-radius: 50vh;

    &.draft {
      background: var(--draft);
    }

    &.inviting {
      background: var(--inviting);
    }

    &.execution {
      background: var(--execution);
    }

    &.completed {
      background: var(--completed);
    }
  }

  // .percent {
  //   position: relative;
  //   width: 45px;
  //   height: 45px;
  //   border-radius: 50%;
  //   background: var(--lightest);

  //   z-index: 10;

  //   --r: 65;
  //   --p: calc(3.14 * 2 * var(--r));
  //   --percents: 100;
  //   --dashoffset: calc(var(--p) - (var(--p) * var(--percents))/100);
  //   --circle-color: var(--primary);

  //   &.warning {
  //     --circle-color: var(--warning);
  //   }

  //   &.success {
  //     --circle-color: var(--success);
  //   }

  //   svg {
  //     position: relative;
  //     width: 45px;
  //     height: 45px;
  //     z-index: 10;
  //   }
  //   svg circle {
  //     width: 100%;
  //     height: 100%;
  //     fill: none;
  //     stroke: var(--circle-color);
  //     stroke-width: 10;
  //     stroke-linecap: round;
  //     stroke-dasharray: var(--p);
  //     stroke-dashoffset: var(--dashoffset);

  //     transition: stroke-dashoffset .3s ease-in-out;
  //   }
  // }

  // .percent .number {
  //   position: absolute;
  //   top: 0;
  //   left: 0;
  //   width: 100%;
  //   height: 100%;
  //   display: flex;
  //   justify-content: center;
  //   align-items: center;
  //   border-radius: 50%;
  // }
  // .percent .number h2 {
  //   color: var(--primary);
  //   // font-weight: 700;
  //   font-size: 28px;
  //   transition: 0.5s;
  // }

  // @keyframes rotateIn {
  //   from {
  //     stroke-dashoffset: 0;
  //   }
  // }

  @keyframes fadeIn {
    from {
      opacity: 0;
    }
  }
}
