.access-link {
  max-width: 900px;
  color: white;

  &__actions {
    margin: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .info-line {
    margin: 0 20px;
    padding-top: 10px;
    // border: solid 1px red;

    a {
      color: var(--dark-link);
      text-decoration: underline;
    }
  }

  &__desc {
    line-height: 1.5;
  }

  &__email {
    padding: 10px;
    font-size: var(--text-big);
    color: var(--dark-orange);
  }

  b {
    color: var(--warning);
  }
}
