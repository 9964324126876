.document-container {
  height: calc(100vh - 60px);
  min-width: 745px;
  width: 100%;

  display: flex;
  flex-direction: column;

  position: sticky;
  top: 100px;

  .dropdown {
    padding: 0;

    &__selected {
      width: 100%;

      &__label {
        justify-content: flex-end;
        border-radius: 10px;
      }
    }
  }
}

.document-actions {
  position: absolute;
  background: white;
  border-radius: 4px 0px 0 0;
  background: rgba(var(--darker-rgb), 0.8);
  color: white;
  font-size: var(--text-small);
  left: 0;
  right: 0;
  z-index: 999;
  backdrop-filter: blur(3px);

  &__tabs {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 15px;

    li {
      height: 100%;
      padding: 5px 10px;
      cursor: pointer;
    }

    .active {
      background: var(--dark);

      &.doc {
        background: var(--lightest);
        color: var(--darker);
      }
    }
  }
}

.document-preview {
  position: relative;
  overflow-y: auto;
  color: black;
  z-index: 2;
  flex: 1;
  padding: 1em 0.5em;
  padding-bottom: 10vh;

  &__edit {
    .ProseMirror {
      padding: 3em;
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: 0.5em;
  }

  &__document {
    width: 724px;
    // margin: auto;
    padding: 30px;
    padding-bottom: 40px;
    font-family: arial;
    background: white;
    border-radius: 4px;
    margin-top: 1em;
  }

  .offline-signature {
    border: solid 1px var(--grey);
    border-radius: 5px;
    background: white;
    padding: 0.5em;
    color: black;
    display: flex;
    align-items: center;
    gap: 1em;
    width: 300px;
    height: 120px;

    line-height: 1.5;
    position: relative;
  }

  [dr-signature] {
    height: 120px;
    width: 285px;
    display: flex;
    justify-content: center;
    align-items: center;
    background: #64c7cc44;
  }

  &.doc-style,
  .doc-style {
    [dr-amend],
    [dr-text],
    [dr-data],
    [dr-math],
    [dr-when],
    [dr-default],
    [dr-value] {
      --border-color: transparent;
      background: #64c7cc44;
      border-radius: 5px;
    }

    [dr-amend] {
      background: none;
    }
  }

  &__tab {
    background: var(--darker);
    color: white;
    min-height: 100%;
    padding: 15px;
    padding-top: 20px;
    padding-bottom: 60px;
    overflow-y: auto;

    h2 {
      display: flex;
      align-items: center;
      gap: 20px;
    }
  }

  .cols {
    position: relative;

    display: table;
    width: 100%;
    margin: 0;

    & > * {
      display: table-cell;
      padding-left: 3rem;

      width: 50%;
      padding-right: 0.5rem;
    }

    & > p {
      padding-top: 0.5rem;
      padding-bottom: 0.5rem;
    }

    & > *:last-child {
      padding-right: 0rem;
    }
  }

  ol {
    counter-reset: level1;

    & > li {
      counter-increment: level1;
    }
  }

  ul {
    padding-left: 40px;

    & > li {
      list-style: disc;
    }
  }
}

.show-labels .document-preview {
  [dr-signature],
  [dr-text],
  [dr-secondary],
  [dr-data],
  [dr-when],
  [dr-default],
  [dr-wrap],
  [dr-amend],
  [dr-value] {
    &:before,
    &:after {
      white-space: nowrap;
      padding: 0px 5px 2px 5px;
      font-size: 10px;
      border-radius: 3px;
      top: -2px;
      color: white;
      font-weight: normal;
      margin-right: 5px;
      line-height: 1.1;
    }

    &:after {
      left: auto;
      right: -5px;
      color: white;
      z-index: 1;
      margin: 0;
    }

    &:hover {
      &:after {
        z-index: 3;
      }
    }
  }

  [dr-data] {
    &:after {
      content: 'data: ' attr(dr-data);
      color: white;
    }
  }

  [dr-amend] {
    background: var(--light-blue) !important;
    &:after {
      content: 'amend: ' attr(dr-amend);
      background: var(--error);
      color: white;
      z-index: 1;
      top: -2px;
    }
  }

  [dr-format] {
    &:after {
      content: '[format: ' attr(dr-format) ']';
      color: crimson;
    }
  }

  [dr-value] {
    --border-color: var(--warning);
  }

  [dr-when] {
    --border-color: var(--success);

    &:after {
      content: attr(dr-value) ' == ' attr(dr-when);
      background: var(--success);
    }
  }

  [dr-when-not] {
    --border-color: var(--error);

    &:after {
      content: attr(dr-value) ' NOT ' attr(dr-when-not);
      background: var(--error);
    }
  }

  [dr-default] {
    --border-color: var(--success);

    &:after {
      content: attr(dr-value) ' = [Default]';
      background: var(--primary-btn);
    }

    &[dr-when] {
      &::after {
        content: attr(dr-value) ' == ' attr(dr-when) ' OR [Default]';
      }
    }
  }

  [dr-text] {
    &:before {
      content: attr(dr-text);
      background: var(--dark-orange);
    }
  }

  [dr-signature] {
    &:before {
      content: 'Signature ' attr(dr-signature);
      background: var(--error2);
    }
  }

  [dr-secondary] {
    &:before {
      content: attr(dr-text) ' OR ' attr(dr-secondary);
      background: linear-gradient(to right, var(--dark-orange2) 45%, var(--dark) 55%, var(--dark));
    }
  }

  .document-preview__document {
    width: 600px;

    .red {
      border: solid 1px red;
      padding: 10px;
      border-radius: 10px;
      margin: 5px 0;
    }

    .blue {
      border: solid 1px blue;
      padding: 10px;
      border-radius: 10px;
      margin: 5px 0;
    }

    .orange {
      border: solid 1px orange;
      background: rgba(255, 166, 0, 0.466);
      padding: 10px;
      border-radius: 10px;
      margin: 5px 0;
    }

    .pad {
      padding: 1em;
    }

    .flex {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border: solid 1px var(--primary-btn);
      padding: 5px;
      border-radius: 5px;
    }
  }
}

.test-actions {
  margin: 10px;
  display: flex;
  button {
    margin: 0 10px;
  }
}

.debug {
  background: #ffffff99;
  padding: 10px;
  border-radius: 10px;
}
