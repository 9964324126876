.rte-editor {
  .inline-menu,
  .block-menu {
    font-family: 'Poppins';

    ul {
      margin: 0;
      padding: 0;
    }

    li {
      list-style-type: none;
    }

    svg {
      display: block;
    }
  }

  .inline-menu {
    position: absolute;
    transform: translateY(calc(-100% - 0.5em)) translateX(-50%);
    box-shadow: 0 0 10px 0 var(--grey);
    border-radius: 5px;
    background: var(--dark2);
    pointer-events: all;
    padding: 3px;

    &.isBlock {
      transform: translateY(calc(-100% - 0.5em));
    }

    &__list {
      display: flex;
      gap: 2px;
      border-radius: 5px;
    }

    &__node-name {
      color: var(--lighter);
      font-size: 11px;
      padding: 0.25em 0.5em;
      text-transform: capitalize;
    }

    &__dr {
      justify-content: flex-end;
      position: relative;
    }

    &__item {
      // position: relative;
      list-style: none;
      border-radius: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      // height: 2.5em;
      min-width: 2em;
      cursor: default;
      // color: #666;
      color: white;

      &:hover {
        box-shadow: 0 0 5px 0 #ccc;
        background: var(--light-grey);
        color: #666;
      }

      &.active {
        background: var(--grey);
        color: var(--dark);
      }

      &.disabled {
        color: #ccc;
      }

      &.remove {
        color: var(--error2);
      }
    }

    &__desc {
      font-size: 10px;
    }
  }

  pm-block {
    position: relative;
    display: block;

    &:hover > pm-btn {
      opacity: 0.5;
    }

    & > pm-btn:hover {
      opacity: 1;
      box-shadow: 0 0 5px 0 #999;
    }

    .ProseMirror-selectednode > pm-btn {
      opacity: 0;
    }
  }

  pm-btn {
    position: absolute;
    width: 18px;
    height: 18px;
    margin-right: 5px;
    right: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50vh;
    color: white;
    opacity: 0;
    font-size: 11px;
    cursor: pointer;
    background: URL('dots-vertical.svg');
    background-size: 18px 18px;
    transition: 0.2s;
    z-index: 1;
  }
}
.floating-menu {
  position: absolute;
  z-index: 20;
  // border: solid 1px red;
  // padding: 10px;
}
