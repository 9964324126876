.activity-expand {
  // background: var(--lighter);
  border-radius: 5px;
  padding: 1px .5em;
  // color: black;

  &__box {
    display: flex;
    justify-content: space-between;
  }

  &__stats {
    margin-bottom: 1em;
    color: var(--lighter);

    &__item {
      margin: .5em;
      display: flex;
      // border: solid 1px red;
      // padding: 5px 0;

      label {
        width: 100px;
        // border: solid 1px red;
        display: inline-block;
      }

      div {
        // border: solid 1px blue;
        min-width: 30px;
        text-align: center;
      }
    }
  }

  &__changes {
    color: black;

    &__item {
      background: var(--lightest);
      margin: .5em 0;
      border-radius: 5px;
    }

    &__header {
      padding: .25rem .5rem 0;
      color: var(--medium);
      font-size: var(--text-small);
    }

    &__info {
      display: flex;
      padding: .25em .5em;
      align-items: center;

      .--action {
        // width: 80px;
        font-size: var(--text-small);
        // border: solid 1px red;
        height: 20px;
        width: 20px;
        margin-right: 1em;
        display: flex;
        justify-content: center;
        align-items: center;

        svg {
          background: var(--success);
          color: white;
          border-radius: 50vh;
          padding: 2px;
          height: 20px;
          width: 20px;
        }

        &.update svg {
          background: var(--primary-btn);
        }

        &.remove svg {
          background: var(--error);
        }
      }

      .--orgValue,
      .--value {
        flex: 1;
      }

      .--cpValue {
        background: var(--cp-bg);
      }
    }
  }
}
