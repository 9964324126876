.ani {
  &__parties {
    display: flex;
    gap: .5em;

    .party {
      padding: .5em;
      flex: 1;
      // border: solid 1px orange;

      .list {
        // border: solid 1px red;
        margin-left: 5px;
        position: relative;

        &:before {
          content: '';
          position: absolute;
          top: 0;
          left: 13px;
          bottom: 10px;
          width: 2px;
          background: var(--medium);
          z-index: 0;
        }

        & > li {
          // z-index: 10;
        }
      }
    }

    .user-card {
      display: flex;
      align-items: center;
      gap: .5em;
      // background: none;
      // border: solid 1px orange;
      // padding-top: 2em;
      padding-right: 1em;
      margin-top: 2em;

      &__visited {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: flex-end;
      }

      &__name {
        margin-right: 1em;
      }

      &__resend {
        margin: 0 1em;
      }

      &__last-send {
        color: var(--dark);
      }
    }

    .message {
      display: flex;
      gap: 1em;
      margin: 2px 0;
      padding: 3px 5px;
      align-items: center;
      // background: white;
      z-index: 1;
      position: relative;

      .--nr {
        // border: solid 1px var(--dark);
        display: flex;
        align-items: center;
        justify-content: center;
        width: 18px;
        height: 18px;
        border-radius: 50vh;
        font-size: var(--text-small);
        background: var(--medium);
        color: white;
        flex-shrink: 0;
      }

      .--name {
        border-bottom: solid 1px var(--lighter);
      }

      .--warning {
        color: var(--dark-orange);
      }

      .--label {
        border-radius: 50vh;
        font-size: var(--text-small);
        background: var(--success);
        display: inline-flex;
        color: white;
        padding: 1px 5px;
        z-index: 5;
        position: relative;

        &.failed {
          background: var(--error);
        }

        &.skipped {
          background: #aaa;
          // color: var(--medium);
        }

        &.noTpl {
          background: var(--dark-orange);
        }
      }
    }
  }
}
