.form-group {
  // --bg: #fffffff2;
  --bg: #fefefe;
  --color: black;
  --label-color: black;
  // --label-bg: #e6e5e587;

  --label-shadow-bg: #979696;

  background: var(--bg);
  color: var(--color);
  border-radius: 5px;
  position: relative;
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;

  a {
    cursor: pointer;
    text-decoration: underline;
    color: var(--medium);
  }

  &.execution {
    --bg: none;
    --label-bg: var(--darker);
    --label-color: white;

    .form-group__children {
      padding: 0;
      padding-top: 10px;
    }
  }

  &.no-children {
    background: none;
    .form-group__icon {
      color: var(--dark);
    }

    &.open {
      background: var(--bg);
      .form-group__icon {
        color: var(--label-color);
      }
    }
  }

  &:focus {
    outline: none;
  }

  &:focus-within {
    box-shadow: 0 0px 5px var(--lighter);
  }

  &__label {
    font-weight: bold;
    padding: 0px 10px 0 5px;
    background: var(--label-bg);
    color: var(--label-color);
    border-radius: 5px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .form-label {
      color: white;
    }

    h3 {
      padding: 10px;
    }
  }

  &__icon {
    border-radius: 50vh;
    height: 30px;
    width: 30px;
    color: var(--label-color);
    display: flex;
    align-items: center;
    justify-content: center;

    svg {
      transition: 0.3s;
      transform: rotate(-90deg);
    }

    &.allCompleted {
      background: var(--warning);
    }

    &.allMatched {
      background: var(--success);
      color: white !important;
    }

    &.open svg {
      transform: rotate(0deg);
    }
  }

  &__progress {
    width: 100px;
    margin-right: 10px;
  }

  &__title {
    flex: 1;
    min-height: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    margin: 0 10px;

    h3 {
      display: flex;
      align-items: center;
      gap: 10px;
      padding: 0;
    }

    .state {
      font-size: var(--text-small);
      font-weight: normal;
      background: var(--success);
      color: white;
      border-radius: 50vh;
      padding: 0 10px;
      text-transform: uppercase;
    }

    &.scroll {
      cursor: pointer;
    }
  }

  &__children {
    padding: 0 5px;
    padding-top: 10px;
  }

  &__amend {
    padding: 10px 20px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    font-size: var(--text-small);

    &__content {
      .form-item {
        max-width: none;
      }
    }
  }

  &__message {
    padding: 10px;
    color: var(--dark);
    text-align: center;
  }
}
