.elections .party-add .form-input {
  margin: 0;
}

.party-add {
  font-size: var(--text-medium);
  flex: 1;
  border-radius: 5px;
  min-height: 150px;
  padding: 0 1em;

  .positions__user__card {
    cursor: pointer;
  }

  &.right {
    .party-add__content,
    .positions__header {
      justify-content: flex-end;
      text-align: right;
    }
    .party-add__form {
      margin-left: 2.5em;
      margin-right: 0;
    }

    .positions__user__card {
      flex-direction: row-reverse;
      text-align: right;
    }

    .party-add__counterparty {
      flex-direction: row;
    }

    .table__add__actions {
      justify-content: flex-end;
    }
  }

  &__content {
    button[disabled] {
      --disabled-color: #666;
    }
    margin: 0 5px;
    display: flex;
  }

  &__empty {
    margin-top: 3em;
  }

  &__counterparty {
    display: flex;
    gap: .5em;
    align-items: center;
    justify-content: flex-end;
    flex-direction: row-reverse;

    svg {
      display: flex;
    }
  }

  &__form {
    display: flex;
    flex-direction: column;
    flex: 1;
    margin-right: 2.5em;

    input {
      width: 100%;
    }
  }

  .cp-email {
    color: var(--dark);
    font-size: var(--text-small);
  }

  .form-item {
    padding: 0;
    margin: 0;
  }

  .form-label {
    padding: 0 5px;
    margin: 0;
    margin-bottom: 5px;
    color: var(--darker);

    .required {
      color: var(--error2);
    }
  }

  .error-message {
    background: var(--error);
  }
}
