.confirm-btn {
  --clip-left: polygon(0% 0%, calc(100% - 10px) 0%, 100% 50%, calc(100% - 10px) 100%, 0% 100%);
  --clip-right: polygon(10px 0%, 100% 0%, 100% 100%, 10px 100%, 0% 50%);

  --clip-primary: var(--clip-left);
  --clip-secondary: var(--clip-right);

  --icon-bg: var(--lighter);
  // --icon-bg: white;

  display: flex;
  font-size: var(--text-medium);

  &.reverse {
    --clip-primary: var(--clip-right);
    --clip-secondary: var(--clip-left);

    .container {
      flex-direction: row-reverse;
      background: linear-gradient(90deg, var(--icon-bg), transparent);
      .primary button {
        border-radius: 0 50vh 50vh 0;
      }
      .secondary {
        button {
          border-radius: 50vh 0 0 50vh;
        }
        margin-right: 0;
        margin-left: -5px;
      }
      .btn-icon {
        transform: rotate(180deg);

        &.custom-icon {
          transform: rotate(0);
          padding: 0 5px 0 10px;
        }
      }

      .text {
        margin-right: 0;
        // background: linear-gradient(90deg, var(--grey), white);
      }

      .slide-in {
        animation: slideButtonInReverse 0.2s;
      }
    }
    &.open .container {
      background: linear-gradient(-90deg, var(--icon-bg), #ffffff77, white, white);
    }
  }

  &.white {
    --icon-bg: white;
  }

  .container {
    // background: linear-gradient(90deg, transparent, white);
    background: var(--grey);
    border-radius: 50vh;
    display: flex;
    align-items: center;
    color: var(--blue);

    button {
      border-radius: 50vh;
    }
  }

  .slide-in {
    animation: slideButtonIn 0.2s;
  }

  .primary {
    clip-path: var(--clip-primary);
    button {
      border-top-right-radius: 0;
      border-bottom-right-radius: 0;
    }
  }

  .secondary {
    clip-path: var(--clip-secondary);
    margin-right: -5px;
    button {
      border-top-left-radius: 0;
      border-bottom-left-radius: 0;
    }
  }

  .text {
    padding-right: 40px;
    display: flex;
    align-items: center;
    height: 100%;
    background: linear-gradient(-90deg, var(--grey), white);
    clip-path: var(--clip-secondary);
    margin-right: -30px;
    padding-left: 10px;
    white-space: nowrap;
    cursor: default;
    user-select: none;
  }

  .close {
    padding: 0 5px;
    height: 24px;
    cursor: default;
  }

  .btn-icon {
    height: 24px;
    color: var(--primary);

    &.error {
      color: var(--error);
    }

    &.small {
      height: 18px;
      svg {
        height: 18px;
      }
    }

    &.custom-icon {
      padding: 0 10px 0 5px;
    }
  }

  &.small {
    font-size: var(--text);

    .secondary button {
      padding: 5px 10px;

      font-size: var(--text);
    }
  }

  &.open {
    .container {
      background: linear-gradient(90deg, var(--icon-bg), #ffffff77, white, white);
    }
    .btn-icon {
      padding: 0 5px;
    }
  }
}

@keyframes slideButtonIn {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@keyframes slideButtonInReverse {
  from {
    transform: translateX(100%);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}
