.progress-line {
  flex: 1;
  --progress-line-shadow: 2px 2px 8px 0px var(--color5);
  --progress-line-incomplete: var(--grey);
  --progress-line-completed: var(--dark-orange);
  --progress-line-done: var(--success);
  --progress-line-color: white;

  &.done {
    .progress-bar__step {
      background: var(--progress-line-done);
    }
  }

  &.--completed {
    --progress-line-done: var(--dark-orange);
    --progress-line-completed: var(--lighter);
  }

  &.--matched {
    --progress-line-done: var(--success-dark);
    --progress-line-completed: var(--lighter);
  }

  &.--changed {
    --progress-line-done: var(--primary);
    --progress-line-completed: var(--lighter);
  }

  &.--error {
    --progress-line-done: var(--error);
    --progress-line-completed: var(--lighter);
  }

  &__caption {
    display: flex;
    align-items: center;
    justify-content: center;
    // padding-top: 5px;

    .--label {
      text-align: center;
      font-size: var(--text-small);
      color: #999;
      padding: 0 5px;
    }
    .--percents {
      font-size: var(--text);
      color: var(--progress-line-color);
    }
  }

  &__step {
    height: 10px;
    background: var(--progress-line-completed);
    margin: 2px;
    text-align: center;
    position: relative;
    border-radius: 50vh;
    box-shadow: var(--progress-line-shadow);

    &.multi-level {
      background: var(--progress-line-incomplete);
    }

    .--progress {
      transition: 0.5s;
      background: var(--progress-line-done);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-radius: 50vh;
      box-shadow: var(--progress-line-shadow);
      // border: solid 1px var(--grey);
      // border-bottom-left-radius: 10px;
    }

    .--completed {
      transition: 0.5s;
      background: var(--progress-line-completed);
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      border-radius: 50vh;
      box-shadow: var(--progress-line-shadow);
      // border-bottom-left-radius: 10px;
    }
  }

  &.error {
    .progress-line__step {
      background: var(--error);
    }
  }

  &.active,
  &.error {
    .progress-line__caption {
      .--label {
        font-size: var(--text);
        color: white;
      }
    }
  }

  &.small {
    .progress-line__step {
      height: 6px;
      // border-radius: 0;
    }
  }

  &.dark {
    --progress-line-shadow: none;
    --progress-line-incomplete: #aaa;
    --progress-line-color: black;
  }

  &.in-table {
    .progress-line__step {
      height: 5px;
      box-shadow: none;
      border-radius: 50vh;
      overflow: hidden;
      // border: solid 1px red;

      &.multi-level {
        background: #ccc;
      }

      .--completed,
      .--progress {
        box-shadow: none;
      }
    }
    .progress-line__caption {
      .--percents {
        font-size: var(--text-small);
        // color: black;
        color: inherit;
      }
      .--label {
        font-size: 9px;
        display: none;
      }
    }
  }
}
