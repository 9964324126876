.toolbar {
  border-top: solid 1px var(--dark);
  color: white;
  position: sticky;
  top: 0;
  z-index: 80;

  &.open {
    animation: slidein .2s ease-in-out;
  }

  .--border,
  .--border-left {
    position: relative;
    &:before,
    &:after {
      content: '';
      width: 1px;
      height: 40px;
      margin: 10px;
      background: var(--dark2);
    }
  }

  .--border-left {
    &:after {
      content: none;
    }
  }

  &__main {
    position: relative;
    box-shadow: 0 2px 12px var(--medium);
    z-index: 91;
    background: var(--darker);
  }

  &__line {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__item {
    flex: 1;
    display: flex;
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    position: relative;

    & > div {
      position: relative;
    }
  }

  .waiting {
    color: white;
    background: var(--warning);
    padding: 3px 1em;
    border-radius: 50vh;
    // text-transform: uppercase;
    // font-weight: bold;
    font-size: var(--text-medium);
  }

  &__invitation {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &__btn {
      margin: 0 10px;
    }
  }

  &__header {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;

    margin: 0 1em;

    max-width: 700px;

    .version {
      color: #ccc;
      border-radius: 50vh;
      background: var(--grey);
      color: var(--blue);
      padding: 0 .5em;
      font-size: 11px;
      font-weight: bold;
    }
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 3px 0;
    margin-left: .5em;
  }

  &__cp {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 10px;
    background: linear-gradient(90deg, var(--darker), var(--dark));
    height: 60px;

    .--add-cp {
      margin-left: 15px;
    }

    .user-card__children {
      margin-left: 15px;
    }

    &__party {
      &.--me {
        text-align: right;
        display: none;

        &.active {
          .user-card {
            background: var(--dark);
          }
        }
      }

      &.--cp {
        &.active {
          .user-card {
            background: var(--dark);
          }
        }
        [disabled] {
          background: none;
          color: #999;
        }
      }
    }

    &__icon {
      padding: 0 5px;
    }
  }

  .icon {
    &.waiting {
      display: flex;
      align-items: center;
      flex-direction: row;
      justify-content: center;
      color: var(--warning);
      border: solid 1px;
      border-radius: 50px;
      margin-left: 5px;
      padding: 7px;
      background: var(--darker);
      padding-left: 12px;
    }

    svg {
      margin: 0 5px;
    }
  }

  &__item {
    position: relative;
  }

  &__dd {
    border-radius: 3px;
    position: absolute;
    background: var(--dark);
    top: 100%;
    right: 0;
    margin-top: .5em;
    box-shadow: 0 2px 12px var(--primary);

    &__bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      z-index: 1;
    }

    ul {
      z-index: 1;
      position: relative;
    }

    &__title {
      background: var(--darker);
      padding: .5em 1em;
    }

    &__btn {
      background: none;
      padding: 1em;
      white-space: nowrap;
      display: flex;
      align-items: center;
      justify-content: flex-end;
      flex-direction: row-reverse;
      gap: 1em;
      cursor: pointer;
      border-radius: 3px;
      width: 100%;

      svg {
        height: 24px;
        width: 24px;
      }

      &:hover {
        background: var(--dark2);
      }
    }
  }

  &__btn {
    background: var(--grey-dark);
    flex-direction: column;
    font-size: var(--text-small);
    color: var(--grey);
    margin: 0 3px;
    border-radius: 3px;
    cursor: pointer;
    transition: 0.3s;
    min-width: 40px;
    padding-left: 1em;

    &__label {
      display: flex;
      gap: .5em;
      align-items: center;

      svg {
        margin: -5px;
      }
    }

    &:hover {
      background: var(--dark2);
      &[disabled] {
        background: none;
      }
    }

    &.active {
      background: var(--dark2);
      color: white;
      svg {
        transform: rotateY(-180deg);
        // color: var(--warning);
      }
    }

    svg {
      transition: .3s;
    }

    &.open {
      background: var(--dark);
      svg {
        transform: rotateX(180deg);
        color: var(--warning);
      }
    }
  }

  &__secondary {
    height: 50px;
    z-index: 100;
    position: relative;
    margin-top: 1px;
    background: #162130e0;

    &__content {
      animation: slideInLeft 0.3s;
      position: relative;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
    }
  }
}

.share {
  position: absolute;
  z-index: 5;
  padding: 20px;
  width: 300px;
  background: #999;
  right: 0;
  border-radius: 5px;
  margin-top: 3px;
  color: white;

  &__row {
    padding: 10px 0;
  }
}
