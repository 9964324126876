.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 200;
  background: #ffffff77;
  display: flex;
  align-items: center;
  justify-content: center;
  backdrop-filter: blur(5px);
  overflow-y: auto;
  overscroll-behavior: contain;

  &__box {
    min-width: 300px;
    padding: 20px;
    border-radius: 5px;
    background: var(--dark);
    color: white;

    & > header,
    & > footer {
      display: flex;
      align-items: center;
      justify-content: space-between;
      color: var(--warning);

      svg {
        color: var(--lightest);
      }
    }

    h1 {
      flex: 1;
      margin: 0 10px;
      font-size: var(--text-big);
    }
  }

  &__content {
    padding: 10px 0 0;
  }

  &__pre {
    // border: solid 1px red;
    background: var(--lighter);
    color: black;
    padding: 10px;
    border-radius: 10px;
  }

  &__list {
    margin-top: 30px;
    li {
      margin: 20px;
      font-size: var(--text-medium);
      list-style: circle;
      margin-left: 40px;
    }
  }

  &__info-icon {
    background: var(--primary-btn);
    border-radius: 50vh;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .btn-close {
    background: none;
    margin-left: 30px;
    padding: 0;
    margin: 0;
  }

  .warning {
    color: var(--dark-orange);
  }

  .loading .cssload-loader {
    --animation-color: var(--dark2);
  }
}
