.company {
  margin: 2em;
  max-width: 1100px;
  display: flex;
  flex-direction: column;
  gap: 3em;

  &__logo {
    background: white;
    border-radius: 3px;

    display: flex;
    align-items: center;

    .user-card__avatar {
      --avatar-size: 150px;
      width: 250px;
      max-height: 150px;
      border-radius: 5px;
      margin-right: 10px;

      &.logo {
        height: auto;
      }
    }

    h2 {
      padding: 10px 0;
    }
  }

  aside {
    flex: 1;
    margin-left: 20px;
  }

  &__users {
    color: var(--darker);
    max-width: none;

    h2 {
      margin: 10px 0;
      font-weight: normal;
    }

    .search-table {
      --table-content-bg: var(--light-grey);
    }

    .perm-view {
      font-size: var(--text-small);
      margin: 2px;
      list-style: disc;
    }

    .radio__children {
      margin-left: 2em;
    }

    .form-item .checkbox__item {
      width: auto;
    }
  }
}
