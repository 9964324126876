.user-profile {
  position: relative;
  margin-right: 20px;

  .dropdown {
    --parent-shadow: 0 0 10px 0 var(--dark);
  }

  .dropdown__list {
    max-height: none;
  }

  &__preview {
    display: flex;
    align-items: center;
    cursor: pointer;
  }

  li {
    margin: 0;
  }

  .company-logo {
    max-height: 100px;
    max-width: 150px;
    border-radius: 10px;
  }

  &__photo {
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50vh;
    overflow: hidden;
    margin: 0 10px;

    .user-card__avatar img {
      height: 30px;
    }

    .medium {
      height: 65px;
      // margin-right: 10px;
    }

    .letters {
      background: var(--dark);
      border-radius: 50%;
      width: 30px;
      height: 28px;
      padding-bottom: 2px;
      display: inline-flex;
      align-items: center;
      justify-content: center;
    }
  }

  &__info {
    text-align: center;
    padding: 10px;
    min-width: 180px;
    cursor: pointer;

    .user-profile__photo {
      width: 65px;
      height: 65px;
      margin: 10px auto;

      .letters {
        width: 65px;
        height: 63px;
        font-size: var(--text-big);
        color: white;
      }
    }
  }

  &__email {
    font-size: 11px;
    color: #ccc;
  }

  &__desc {
    font-size: 13px;
    color: #999;

    label {
      color: black;
      padding-right: 5px;
    }
  }

  &__close-bg {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }

  &__menu {
    border: solid 1px red;

    min-width: 200px;
    position: absolute;
    top: 100%;
    right: 0;
    background: white;
    color: black;
    box-shadow: 1px 1px 6px #666;

    padding: 10px;

    &__list {
      margin-top: 5px;
      padding-top: 5px;
      border-top: solid 1px #ccc;

      li {
        display: flex;
        align-items: center;
        padding: 5px;
        margin: 3px;
        cursor: pointer;
        font-size: 19px;

        svg {
          margin-right: 10px;
          margin-bottom: -2px;
          color: #999;
          height: 30px;
          width: 30px;
        }
        &:hover {
          background: #eee;
        }
      }
    }
  }

  &:hover .user-profile__menu {
    display: block;
  }

  .beta-testing {
    --banner-color: var(--error);
    transform: rotate(0);
    // filter: none;
    font-size: 11px;
    text-transform: uppercase;
  }
}
