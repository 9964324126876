.btn {
  --disabled-color: #ccc;
  --button-font-size: var(--text-medium);

  background: var(--primary);
  padding: 0.5em 1em;
  border-radius: 50vh;
  font-size: var(--button-font-size);
  color: white;
  box-sizing: content-box;
  transition: 0.3s;
  position: relative;
  overflow: hidden;
  display: flex;
  align-items: center;
  gap: 0.5em;
  white-space: nowrap;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-items: center;
  }

  &__aside {
    margin-right: -0.5em;
    display: flex;
    align-items: center;
    align-self: stretch;
    border-left: solid 1px var(--grey-medium);

    svg {
      margin: -3px;
    }
  }

  &:after {
    content: '';
    background: #ffffff55;
    display: block;
    position: absolute;
    padding-top: 300%;
    padding-left: 350%;
    margin-left: -20px !important;
    margin-top: -50%;
    opacity: 0;
    transition: all 1s;
    border-radius: 50vh;
  }

  &:active:after {
    padding: 0;
    margin: 0;
    opacity: 1;
    transition: 0s;
  }

  &.cancel {
    background: none;
    box-shadow: inset 0 0 0px 1px currentColor;
    color: inherit;
  }

  &.dark {
    --disabled-color: var(--medium);
    --link: var(--primary-btn);
    border: solid 1px var(--dark);
    color: var(--dark);
    background: none;
  }

  &.light {
    border: solid 1px var(--dark);
    color: var(--darker);
    background: var(--lighter);
  }

  &.white {
    background: white;
    color: var(--primary);
    border: none;
    box-shadow: none;
  }

  &.link {
    background: none;
    border: none;
    color: var(--primary);
    text-decoration: underline;
    cursor: pointer;
  }

  &.small {
    padding: 5px 10px;
    --button-font-size: var(--text);
  }

  &.mini {
    padding: 0;
    --button-font-size: var(--text-small);
  }

  svg {
    display: block;
    margin: -5px -3px;
  }

  &.icon {
    flex-direction: column;
    background: none;
    padding: 0;

    svg {
      padding: 0;
      margin: 0;
    }

    &.success {
      color: white;
      background: var(--success);
      font-weight: bold;
    }
  }

  &.round {
    border-radius: 50vh;
  }

  &.error {
    background: var(--error);
    border-color: var(--error);
  }

  &.warning {
    background: var(--warning);
    border-color: var(--warning);
    color: var(--darker);
  }

  &.success {
    background: var(--success);
    border-color: var(--success);
  }

  &.clear {
    display: flex;
    align-items: center;
    color: var(--lighter);
    background: none;
    padding: 0;
    border: none;
    border-radius: 3px;

    svg {
      margin: 0;
      margin-right: 10px;
      color: var(--lightest);
    }
  }

  &.primary {
    background: var(--primary);
    border: none;
  }

  & ~ .btn,
  & ~ .confirm-btn {
    margin-left: 5px;
  }

  &.remove {
    color: var(--error);
    background: none;
    padding: 0.5em;
  }
}

[total] {
  position: relative;
  &:after {
    content: attr(total);
    position: absolute;
    background: var(--error);
    color: white;

    height: 16px;
    min-width: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50vh;
    right: 7px;
    top: -3px;
    box-sizing: border-box;
    display: block;
    font-weight: 500;
  }
}

[total='0']:after {
  content: none;
}
button[disabled].btn,
button[disabled] {
  color: var(--grey-medium);
  cursor: default;
  opacity: 0.7;
  background: none;

  &:hover {
    // color: #666;
    box-shadow: none;
  }
}

select.small {
  padding: 5px 10px;
}
