.document-list {
  aside {
    margin-top: 3em;
  }

  .p1__header:before {
    content: none;
  }

  .filter__content {
    padding: 0;
  }
  .p1__header {
    position: relative;
    top: auto;

    // h1 {
    //   padding-top: 0;
    // }
  }
}

.doc-version {
  height: 36px;
  width: 36px;
  color: var(--dark);
  display: block;
}

.restricted {
  display: inline-flex;
  align-items: center;
  border-radius: 50vh;
  color: var(--success);
  font-weight: bold;
}
