.fonts-loaded {
  font-family: 'Poppins';

  .layout__footer__block {
    opacity: 1;
  }
}

.layout {
  min-height: 100vh;
  display: grid;
  flex-direction: column;
  grid-template-rows: 40px auto 100px;
  // background: url('./images/light-texture-bg.png');

  &.no-footer {
    grid-template-rows: 40px auto 0;

    & > footer {
      display: none;
    }
  }

  &__header {
    padding: 20px;
    background: #676b74;
  }

  &__main {
    flex: 1;
    height: 100%;

    &.hasAside {
      display: flex;
    }
  }

  &__content {
    flex: 1;
    display: flex;

    & > div {
      flex: 1;
    }
  }

  &__modal {
    filter: blur(3px);
  }
}

.main {
  padding: 1em;
  & > h1 {
    margin: 0 10px;
  }
}

.split-page {
  display: flex;
  margin: 0 auto;
  align-items: flex-start;
  color: black;
  gap: 1em;

  &__main {
    flex: 5;
    display: flex;
    justify-content: flex-end;

    & > div {
      flex: 1;
      width: 100%;
      margin: 1em 0.5em;
    }
  }

  &__aside {
    flex: 4;
    position: sticky;
    top: 60px;
    display: flex;
    justify-content: flex-end;
  }

  &__side {
    flex: 1;
    margin: 5px;
  }
}

.page {
  padding: 20px 0;

  &__box {
    padding: 20px;
    background: #ffffff80;
    border-radius: 18px;
  }
}

.page-inner {
  padding: 20px;
}

body {
  background: var(--lightest);
}

.desc {
  font-size: var(--text-small);
  font-weight: normal;
  color: #999;
  white-space: normal;
  i,
  b,
  span {
    color: var(--darker);
  }
}

.date {
  font-size: var(--text-small);
  color: #999;
  font-style: italic;
}

[number] {
  &:before {
    content: attr(number);
    // font-size: var(--text-big);
    font-weight: bold;
    color: var(--lightest);
    background: var(--dark);
    display: inline;
    padding: 0 0.5em;
    border-radius: 50vh;
  }
  &.small:before {
    font-size: var(--text-small);
  }

  &.success:before {
    background: var(--success);
  }
  &.warning:before {
    background: var(--dark-orange);
  }
}

.email {
  font-size: var(--text-small);
  color: #999;
}

.avatar {
  margin-top: 5px;
  height: 36px;
  border-radius: 50%;
}

.bg-btn {
  &:before {
    content: '';
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
  }
}

.section {
  &.--dark {
    background: rgba(var(--darker-rgb), 0.75);
    color: white;
  }

  &__header {
    padding: 10px 20px;
    font-size: var(--text-big);
    background: #e6e5e5;
    border-radius: 5px;
    font-weight: bold;
    color: black;
  }

  &__content {
    display: flex;
    color: black;

    & > * {
      flex: 1;
      margin: 20px;
    }
  }
}

.flex,
.block {
  display: flex;
  gap: 5px;
  align-items: center;
}
