.activities {
  --icon-color: var(--darker);
  --icon-bg: var(--lightest);
  --version-bg: var(--medium);
  --user-box-bg: var(--dark);

  --comment-left: 0px;
  --comment-right: 0px;
  --comment-bg: #e9f2fa;

  position: relative;
  // margin-top: 10px;

  .sendToCounterparty {
    --comment-bg: #e9f2fa;
    --icon-color: var(--success);
  }

  .receivedFromCounterparty {
    --icon-color: var(--dark-orange2);
    --version-bg: var(--dark-orange);
  }

  .progress-line.in-table {
    width: 60px;
    flex: 0;
    flex-basis: 60px;
    .progress-line__step {
      height: 2px;
    }
    .progress-line__caption {
      display: none;
    }
  }

  //============================================================================
  .counterparty {
    --comment-bg: var(--cp-bg);
    --user-box-bg: var(--dark2);
    // border-radius: 15px;
    // background: linear-gradient(to right, transparent 5%, var(--darker) 25%, var(--darker), transparent);

    .activities__header {
      flex-direction: row-reverse;
    }

    .activities__progress {
      justify-content: flex-end;
      display: flex;
      flex-basis: 60px;
    }
    .activities__title .desc {
      justify-content: flex-end;
    }

    .activities__comment:before {
      left: auto;
      right: 70px;
    }

    .activities__user {
      flex-direction: row-reverse;
      text-align: right;

      &:before {
        left: -8px;
        right: auto;
        border-top-right-radius: 100%;
        border-bottom-left-radius: 0;
      }
    }

    [version]::after {
      right: auto;
      left: 5px;
    }
  }

  // .comment {
  //   .desc {
  //     color: var(--darker);
  //   }
  // }

  &::before {
    content: '';
    position: absolute;
    width: 0x;
    top: 15px;
    bottom: 15px;
    left: 50%;
    border: 1px dashed var(--lighter);
    z-index: 0;
  }

  &__item {
    z-index: 1;
    position: relative;
    padding: 1px 5px;

    border-radius: 5px;
    cursor: pointer;

    &:hover {
      box-shadow: 0 0 5px var(--warning);
    }

    &.active {
      // box-shadow: 0 0 5px var(--warning);
      background: var(--user-box-bg);
      // border: solid 1px var(--lighter);
      margin: 10px 0;
    }

    &.internal {
      position: relative;

      .activities__user {
        padding: 3px;
        background: none;

        &:before {
          background: none;
        }
      }

      .activities__icon {
        height: 32px;
        width: 32px;
        background: var(--medium);
        color: var(--lightest);
        padding: 0;
        margin-left: 5px;
        // cursor: pointer;
        svg {
          width: 18px;
          height: 18px;
        }
      }

      [version]::after {
        font-size: var(--text-small);
        background: var(--medium);
      }
    }

    &.allowToSign .activities__icon {
      color: var(--success2);
    }

    &.denyToSign .activities__icon {
      color: var(--error);
    }
  }

  &__header {
    padding: 10px 0;
    display: flex;
    align-items: center;
    gap: 20px;
  }

  &__icon {
    width: 42px;
    height: 42px;

    border-radius: 50vh;
    border: solid 3px var(--dark2);
    background: var(--icon-bg);
    display: flex;
    align-items: center;
    justify-content: center;
    padding-bottom: 2px;
    color: var(--icon-color);
    box-shadow: 0 0px 10px -5px;

    svg {
      width: 24px;
    }
  }

  &__progress {
    margin-top: 5px;
    color: white;
    text-align: center;
    opacity: .5;
  }

  &__title {
    flex: 1;
    padding: 5px;

    .desc {
      display: flex;
      align-items: center;
      gap: 5px;

      svg {
        height: 14px;
        width: 14px;
      }
    }
  }

  &__user {
    flex: 1;

    position: relative;
    display: flex;
    align-items: center;
    gap: 10px;
    // font-size: var(--text-small);
    background: var(--user-box-bg);
    padding: 5px;
    border-radius: 5px;

    &::before {
      content: '';
      background: var(--user-box-bg);
      position: absolute;
      width: 15px;
      height: 15px;
      top: 50%;
      right: -8px;
      transform: translateY(-50%) rotate(45deg);
      border-bottom-left-radius: 100%;
    }
  }

  &__comment {
    background: var(--comment-bg);
    color: black;
    padding: .5em 1em;
    // padding-top: 25px;
    border-radius: 5px;
    // margin-top: -33px;
    margin-left: var(--comment-left);
    margin-right: var(--comment-right);
    margin-bottom: 10px;
    line-height: 1.6;
    white-space: break-spaces;
    position: relative;
    z-index: -1;

    &::before {
      content: '';
      background: var(--comment-bg);
      position: absolute;
      width: 15px;
      height: 15px;
      top: -8px;
      left: 70px;
      transform: rotate(45deg);
      border-bottom-right-radius: 100%;
    }
  }

  [version] {
    position: relative;
    &::after {
      content: 'v ' attr(version);
      position: absolute;
      border-radius: 50vh;
      background: var(--version-bg);
      color: white;
      z-index: 1;
      font-size: var(--text-small);
      opacity: .7;
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 2px 5px;

      top: 50%;
      right: 5px;
      transform: translateY(-50%);
    }
  }

  &__today {
    // border: solid 1px red;
    cursor: default;
    .activities__user {
      visibility: hidden;
    }

    .activities__icon {
      background: var(--darker);
      color: white;
    }

    &:hover {
      box-shadow: none;
    }
  }
}
