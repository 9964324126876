.app {
  &__header {
    position: relative;
    z-index: 100;
    top: 0;
    display: flex;
    background: var(--header);
    // background: #203042;
    color: white;
    padding: 0 1em;
    align-items: center;
    height: 40px;
    font-size: var(--header-text);

    &.sticky {
      position: sticky;
    }
  }

  &__logo {
    // flex: 1;
    svg {
      color: var(--dark-orange) !important;
      padding: 8px 0 5px;
      background: none !important;
    }
  }

  &__menu {
    align-self: stretch;
    flex: 1;
  }

  &__notifications {
    position: relative;
    margin: 0 15px;
    // padding-right: 12px;
    color: var(--lighter);
    display: flex;

    &:after {
      animation: notificationFlash 0.3s;
    }

    &:hover {
      color: white;
    }
  }

  &__user {
    opacity: 0;
    transition: .3s;
  }
}

.fonts-loaded .app__user {
  opacity: 1;
}

.beta-testing {
  --banner-color: var(--medium);
  color: var(--banner-color);
  border: solid 1px var(--banner-color);
  border-radius: 3px;
  padding: 2px 5px;
  transform: rotate(-8deg);
  // box-shadow: 0 0 3px 3px var(--banner-color);
  // text-shadow: 0 0 3px var(--banner-color);
  filter: drop-shadow(0 0 .175rem red);
  display: flex;
  align-items: center;
  justify-content: center;
  // font-size: 11px;
  cursor: pointer;
  transition: .3s;

  &:hover {
    --banner-color: var(--error2);
    // filter: drop-shadow(0 0 .175rem var(--error2));
    transform: rotate(-5deg);
  }
}

@keyframes notificationFlash {
  from {
    transform: scale(2);
    opacity: 0;
  }
  to {
    transform: scale(1);
    opacity: 1;
  }
}
