.layout__footer {
  position: relative;
  z-index: 1;
  height: 100%;
  background: var(--footer);
  display: flex;
  align-items: center;
  justify-content: center;
  color: white;

  &__block {
    margin: 0 50px;
    display: flex;
    align-items: center;
    opacity: 0;
    transition: .3s;

    svg {
      height: 42px;
      min-width: 42px;
      background: var(--lighter);
      color: var(--darker);
      border-radius: 50vh;
      padding: 5px;
      margin-right: 1em;
    }
  }
}
