.elections {
  max-width: 900px;

  &__form {
    margin: 1em;
  }
}

.negotiation {
  background: var(--lighter);
  min-height: 100%;
}

.neg-aside {
  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 1em 0.5em;
  }
}
