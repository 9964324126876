.confirm-box {
  &__wrapper {
    position: relative;
  }

  // --corner-bg: #3a6aa7;
  --corner-bg: var(--primary-btn);
  flex: 1;
  background: linear-gradient(140deg, var(--corner-bg) -50%, var(--darker) 35%);
  padding: 10px;
  margin: 10px 0;
  border-radius: 4px;
  margin: 5px;
  animation: slidein .3s;
  box-shadow: 0 0px 5px 0px var(--darker);
  color: white;

  &.error {
    --corner-bg: var(--error);
  }

  &.warning {
    --corner-bg: var(--dark-orange);
  }

  &.success {
    --corner-bg: var(--success);
  }

  &__main {
    display: flex;
  }

  &__left {
    flex: 2;
  }

  &__right {
    flex: 2;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  &__header {
    display: flex;
    align-items: center;
    padding: 10px;

    h1 {
      font-weight: normal;
    }

    svg {
      margin-right: 20px;
      height: 42px;
      width: 42px;
      color: var(--lighter);
    }
  }

  &__desc {
    padding: 0 10px 10px;
    line-height: 1em;
    color: var(--lighter);
  }

  &__actions {
    display: flex;
    justify-content: center;
    margin-bottom: 10px;
  }

  &__content {
    flex: 1;
    background: rgba(var(--darker-rgb), 0.75);
    border-radius: 5px;
    margin: 5px;

    &.open {
      border-bottom-left-radius: 5px;
      border-bottom-right-radius: 0;

      .confirm-box__toggle svg {
        margin-right: 10px;
        transform: rotateX(180deg);
        color: var(--warning);
      }
    }
  }

  &__toggle {
    display: flex;
    align-items: center;
    flex: 1;
    padding: 20px;
    font-size: var(--text-medium);
    cursor: pointer;
    user-select: none;
    color: white;

    svg {
      margin-right: 10px;
      transition: .5s;
    }
  }

  .last-activity {
    padding: 20px;

    &__comment {
      white-space: pre-wrap;
      line-height: 1.5;
      background: var(--lighter);
      padding: 20px;
      border-radius: 5px;
      margin: 10px 0 0;
      color: var(--darker);
      min-height: 160px;
    }
  }
}
