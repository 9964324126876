.story {
  --box-bg: var(--lightest);
  --tips-bg: var(--lighter);

  // border: solid 1px red;
  margin: 20px auto;
  padding: 20px;
  background: var(--box-bg);
  border-radius: 5px;

  &:nth-child(even) {
    --box-bg: var(--lighter);
    --tips-bg: var(--lightest);
  }

  &.right,
  &:nth-child(even) {
    .story__tips {
      padding: 0;
      padding-right: 20px;
    }

    .story__article,
    h3 {
      // border: solid 1px red;
      flex-direction: row-reverse;
    }
  }

  &.left {
    .story__tips {
      padding: 0;
      padding-left: 20px;
    }

    .story__article,
    h3 {
      // border: solid 1px red;
      flex-direction: row;
    }
  }

  &__header {
    margin: 10px 0;
  }

  &__article {
    display: flex;

    aside {
      flex: 1;
    }
  }

  &__pos {
    position: absolute;
    // border: solid 1px red;
    width: 10px;
    height: 10px;
    background: red;
    color: red;
    z-index: 5;
    border-radius: 50vh;
    margin-top: -5px;
    margin-left: -5px;
  }

  &__tips {
    padding-left: 20px;

    &__item {
      // margin: 20px;
      background: var(--tips-bg);
      padding: 10px;
      border-radius: 5px;

      & ~ li {
        margin: 20px 0;
      }

      h3 {
        display: flex;
        align-items: center;
        // border: solid 1px red;
        display: flex;
        align-items: center;
        gap: 5px;

        div {
          flex: 1;
        }

        b {
          width: 34px;
          height: 34px;
          color: red;
          // margin-right: 10px;
          // border: solid 1px blue;
          border-radius: 50vh;
          background: white;
          z-index: 6;
          display: flex;
          align-items: center;
          justify-content: center;
          color: var(--darker);
          border: solid var(--tips-bg) 5px;
        }
      }
    }
  }

  &__image {
    display: flex;
    width: 850px;
    height: 480px;
    // border: solid 1px red;
    position: relative;
    // justify-content: center;
    // margin-right: 20px;
    border-radius: 5px;
    // opacity: .8;

    img {
      width: 850px;
      opacity: .95;
      border-radius: 5px;
    }

    .pre-load {
      .cssload-loader {
        --animation-color: var(--darker);
      }

      // border: solid 4px red;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      color: red;
      z-index: 6;
    }
  }

  &__bg-image {
    flex: 1;
    // background-size: cover;
    // background-position: 14px;
    // background-repeat: no-repeat;
    opacity: .95;
    object-position: top left;
    object-fit: contain;
  }

  pre {
    font-size: var(--text-medium);
    padding: 10px;
    margin: 10px;
    background: white;
    border-radius: 5px;
    color: var(--dark);
  }
}
