.error-message {
  --error-msg-bg: #b314128c;

  // border-radius: 5px 5px 20px 20px;
  // padding: 23px 20px 5px;
  background: var(--error);
  position: relative;
  white-space: pre;
  font-size: 13px;
  white-space: pre-wrap;
  color: white;
  // margin: 0 -1px;
  // margin-top: -20px;
  animation: slideInTop .3s;

  z-index: 1;
  position: absolute;
  // top: 100%;
  top: -15px;
  right: 8px;
  padding: 0 1em;
  border-radius: 5px;
  box-shadow: 0 0 10px 2px #ccc;

  @keyframes slideInTop {
    from {
      transform: translateY(-20px);
      opacity: 0;
    }
    to {
      transform: translateY(0);
      opacity: 1;
    }
  }
}

.form-input {
  position: relative;
  input,
  textarea {
    &.blur.invalid {
      box-shadow: 0 0px 3px var(--error);
    }
  }
}
