.dropdown {
  // --parent-bg: #325776;
  // --parent-bg: var(--light-grey);
  --parent-bg: white;
  --parent-border: solid 1px #ccc;
  --parent-shadow: 0 0 10px -2px #ccc;
  --parent-margin: 0;
  --list-bg: var(--light-grey);
  --group-bg: var(--light-grey);
  --item-bg: var(--light-grey);
  --item-color: black;
  --item-selected: var(--dark);
  --item-hover: var(--lightest);
  --item-font-size: var(--text-medium);

  --position-right: -15px;

  font-weight: normal;
  position: relative;
  display: inline-flex;
  align-items: center;

  time {
    color: var(--dark);
  }

  svg {
    display: flex;
  }

  &.outline,
  &.multi {
    --parent-border: none;
    --parent-bg: var(--dark2);
    // --parent-bg: var(--lighter);
    --parent-margin: 7px;
    --list-bg: none;
  }

  &.outline {
    .dropdown__group {
      margin-top: 10px;
    }
  }

  &.dark {
    --parent-border: none;
    --parent-bg: var(--dark2);
    --item-bg: var(--dark2);
    --button-bg: var(--light-grey);
  }

  &.big {
    --item-font-size: var(--text-big);
  }

  &.small {
    --item-font-size: var(--text);
    --position-right: -31px;
  }

  &.inside {
    .dropdown__open {
      top: auto;
      right: auto;
      left: 0;
      min-width: 100%;
      box-shadow: 0 0 10px #999;

      &::before {
        content: none;
      }
    }

    .dropdown__list {
      max-height: 280px;
    }
  }

  &.right {
    .dropdown__open {
      right: -15px;

      &:before {
        right: 18px;
      }
    }
  }

  &__label {
    margin-right: 10px;
  }

  &__selected {
    cursor: pointer;
    display: flex;
    align-items: center;
    gap: .5em;

    &__label {
      border: solid 1px #ccc;
      border-radius: 6px;
      background: white;
      color: var(--primary-btn);
      display: flex;
      align-items: center;
      cursor: pointer;
      padding: 0 .5em;

      &.--loading {
        color: #999;
      }

      .label {
        padding: 2px 10px;
      }

      label {
        display: flex;
        align-items: center;
        gap: 1em;
      }
    }

    label {
      padding: 0.25em 0.5em;
    }

    &.open {
      &.dropdown__selected {
        visibility: visible;
        opacity: 1;
      }

      &:before {
        content: '';
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        cursor: default;
        z-index: 1;
      }
    }
  }

  &__open {
    position: absolute;
    top: 100%;
    right: var(--position-right);
    color: black;
    z-index: 30;
    border-radius: 4px;
    margin-top: 14px;
    animation: openDD 0.1s ease-in;
    background: var(--parent-bg);
    border: var(--parent-border);
    box-shadow: var(--parent-shadow);

    &::before {
      content: '';
      background: var(--parent-bg);
      border: var(--parent-border);
      // box-shadow: var(--parent-shadow);
      border-bottom: 0;
      border-right: 0;
      position: absolute;
      width: 15px;
      height: 15px;
      border-top-left-radius: 3px;
      border-bottom-right-radius: 15px;
      top: -8px;
      right: 25px;
      transform: rotate(45deg);
    }
  }

  &__list {
    user-select: none;
    list-style: none;
    margin: var(--parent-margin);
    padding: 1px;
    border-radius: 2px;
    overflow: auto;
    max-height: 670px;
    position: relative;
    z-index: 1;
  }

  &__group {
    background: var(--group-bg);
    border-radius: 2px;
    padding: 0;
    overflow: hidden;

    & ~ ul,
    & ~ li {
      margin-top: var(--parent-margin);
    }
  }

  &__item {
    list-style: none;
    color: var(--item-color);
    white-space: nowrap;

    & ~ .dropdown__item {
      padding-top: 0;
    }

    &__btn {
      padding: 0.5em 1em;
      border-radius: 3px;
      cursor: pointer;
      display: flex;
      gap: 1em;
      align-items: center;
      font-size: var(--item-font-size);

      svg {
        color: var(--medium);
      }

      &:hover {
        background: var(--item-hover);
        svg {
          color: var(--darker);
        }
      }

      &.error {
        background: var(--error);
        color: white;
      }

      &.error2 {
        color: var(--error);
      }

      &.warning {
        background: var(--warning);
      }

      &.primary {
        background: var(--primary);
        color: white;

        &:hover {
          background: var(--dark2);
        }
      }

      &.selected {
        background: var(--item-selected);
        color: white;

        svg {
          color: white;
        }

        time {
          color: var(--lighter);
        }
      }

      &[disabled] {
        color: #ccc;
        cursor: default;
        background: none;

        svg {
          color: #ccc;
        }

        &:hover {
          background: none;
        }
      }
    }
  }
}

@keyframes openDD {
  from {
    opacity: 0.3;
    // transform: scale(0.9, 0.9);
    transform: translateY(10px);
  }

  to {
    opacity: 1;
    // transform: scale(1, 1);
    transform: translateY(0);
  }
}
