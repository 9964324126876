.ani {
  &__nav {
    ul {
      display: flex;
      gap: .5em;

      button {
        border-radius: 3px;
      }
    }
  }
}
