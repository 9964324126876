.queue {
  &__jobs {
    li {
      padding: .25em .5em;
      border-radius: 3px;
    }
  }

  &__active {
    background: var(--success);
  }
}
