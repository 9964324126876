.home {
  padding-top: 1px;
  // background: var(--lightest);
  color: var(--darker);
  // color: white;
  padding-bottom: 0px;
  margin: 10px auto;
  border-radius: 5px;
  max-width: 1300px;
  // padding: 20px;

  h1 {
    padding: 20px 0;
    color: white;
  }

  .btn.tab {
    background: var(--darker);
    border-color: var(--darker);

    &.active {
      background: var(--lighter);
      color: var(--darker);
    }
  }
}
