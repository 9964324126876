.date-time {
  --time-date-color: var(--dark);

  border-radius: 2px;
  color: var(--time-date-color);
  width: 80px;
  padding: 0 10px;
  margin: 2px auto;
  line-height: 1;

  &__date {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
  }

  &__month {
    text-align: right;
    font-size: var(--text-small);
    font-weight: bold;
  }

  &__year {
    text-align: right;
    font-size: var(--text-small);
    font-weight: normal;
    color: #999;
  }

  &__day {
    font-size: var(--text-big);
    line-height: 1;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    padding: 0;
    padding-top: 3px;
    padding-left: 3px;
  }

  &__time {
    font-size: var(--text-small);
    font-weight: normal;
    text-align: center;
    color: white;
    background: var(--time-date-color);

    border-radius: 2px;
    line-height: 1;
    padding: 2px 3px 1px 3px;
    margin-top: 3px;
  }
}
