.negotiation-list {
  margin: 0 10px;

  &__title {
    // border: solid 1px red;
    h4 {
      display: flex;
      align-items: center;
      gap: .5em;
    }

    .version-label {
      background: var(--lighter);
      color: white;
      // border: solid 1px var(--dark);
      font-size: var(--text-small);
      padding: 0px .5em;
      border-radius: 50vh;
      font-weight: normal;
      white-space: nowrap;
    }
  }

  .flag {
    background: #81c011;
    // background: var(--dark-orange);
    position: absolute;
    top: -5px;
    left: -10px;
    bottom: -5px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 20px;
    padding-right: 2px;
    color: white;
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
    font-size: var(--text-small);
    letter-spacing: 1px;
    font-weight: bold;

    &__text {
      transform: rotate(-90deg);
      text-transform: uppercase;
    }

    &.updated {
      background: var(--primary-btn);
      font-weight: normal;
    }
  }
  .hasFlag {
    margin-left: 15px;
  }

  .myParty {
    color: var(--darker);
    font-size: var(--text-small);
    white-space: nowrap;
  }

  .counterparty {
    color: var(--dark-orange);
    font-size: var(--text-small);
    white-space: nowrap;
  }

  .summary-item {
    white-space: nowrap;
    font-size: var(--text-small);
    line-height: 1.5;
    font-weight: normal;

    label {
      color: #999;
    }
  }
}

.time-ago {
  position: relative;
  border: solid 1px #ccc;
  padding: 5px 10px;
  padding-left: 15px;
  border-radius: 5px;
  font-size: var(--text-small);
  min-height: 30px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  &__number {
    position: absolute;
    background: white;
    border-radius: 50vh;
    top: 50%;
    transform: translateY(-50%);
    left: -10px;
    font-size: 28px;
  }
}

.label {
  &-draft,
  &-inviting,
  &-active,
  &-agreed,
  &-execution,
  &-completed,
  &-rejected,
  &-signed {
    background: #999;
    color: white;
    border-radius: 50vh;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-small);
    padding: 0 1em;

    font-weight: normal;
    text-transform: capitalize;

    text-transform: uppercase;
    font-size: 11px;
  }

  &-inviting {
    // background: var(--warning);
    background: var(--inviting);
  }

  &-active {
    background: none;
    color: var(--medium);
  }

  &-rejected {
    background: var(--error);
  }

  &-agreed {
    background: var(--medium);
  }

  &-execution {
    background: #6c87cd;
    color: var(--tag-1);
  }

  &-completed {
    background: var(--success);
  }

  &-signed {
    background: none;
    color: var(--dark-orange2);
    margin-top: 5px;
  }
}
