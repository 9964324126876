.date-picker {
  &.empty input {
    background: white;
  }

  &.value input {
    background: var(--my-bg);
  }

  &.matched input {
    background: var(--matched-bg);
  }
}
