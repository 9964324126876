.top-menu {
  display: flex;
  gap: .25em;
  border: solid 1px red;
  box-shadow: 0px 0px 5px 0px #ccc;
  border: none;
  float: none;
  border-radius: 0;
  margin: 0;
  padding: 0.5em 1em;
  background: white;
  position: sticky;
  top: 60px;
  z-index: 11;

  &__group.top-menu__group {
    // border: solid 1px red;
    margin: 0;
    padding: 0;

    display: flex;
    align-items: center;
    gap: 2px;
  }

  &__item.top-menu__item {
    list-style: none;

    --active-bg: var(--lightest);
    --available-bg: white;

    --menu-btn-bg: var(--available-bg);
    --menu-btn-color: #666;

    &.isHidden {
      display: none;
    }

    &.isActive {
      --menu-btn-bg: var(--active-bg);
      display: flex;
    }

    &.isDisabled {
      --menu-btn-color: #ccc;
    }

    button {
      font-size: 11px;
      padding: 0 .5rem;
      border-radius: 5px;
      min-height: 24px;
      background: var(--menu-btn-bg);
      color: var(--menu-btn-color);

      svg {
        height: 16px;
        display: inline-flex;
      }
    }
  }

  &__select {
    position: relative;
    &__label {
      width: 100px;
      height: 24px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding-left: .5em;
      border-radius: 5px;
      background: white;
      border: solid 1px var(--grey);
      // background: var(--lightest);
      color: #666;
      cursor: pointer;

      &:hover {
        color: #333;
      }
    }

    &__bg {
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
    }

    &__options {
      position: absolute;
      // border: solid 1px red;
      border: solid 1px var(--grey);
      background: white;
      left: 0;
      right: 0;
      box-shadow: 0px 3px 5px 0px #ccc;

      ul.top-menu__group {
        flex-direction: column;
        align-items: stretch;
        z-index: 1;
        position: relative;

        button {
          width: 100%;
          color: var(--menu-btn-color);
          padding: .25rem .75rem;
          border-radius: 2px;
          white-space: nowrap;

          &:hover {
            background: var(--grey);
          }

          &[disabled]:hover {
            background: none;
          }
        }
      }

      .h-1 button {
        height: auto;
        font-size: 2em;
      }

      .h-2 button {
        height: auto;
        font-size: 1.5em;
      }

      .h-3 button {
        height: auto;
        font-size: 1.17em;
      }

      .h-4 button {
        height: auto;
        font-size: 1.33em;
      }

      .h-5 button {
        height: auto;
        font-size: 0.83em;
      }

      .h-6 button {
        height: auto;
        font-size: 0.67em;
      }
      // display: none;
    }
  }
}
