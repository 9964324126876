.toggle-btn {
  --line-height: 11px;
  --line-width: 30px;
  --dot-size: 15px;
  --dot-offset: -2px;
  --dot-color: var(--grey-medium);
  --on-dot-color: var(--primary);
  --on-color: var(--matched-border);
  --off-color: #ccc;

  display: flex;
  align-items: center;
  cursor: pointer;

  &.small {
    font-size: var(--text-small);
    --line-height: 9px;
    --line-width: 25px;
    --dot-size: 13px;
    --dot-offset: -2px;
  }

  &.dark {
    --dot-color: var(--darker);
    --off-color: var(--lighter);
    --on-color: var(--dark-orange);
  }

  &__btn {
    position: relative;
    width: var(--line-width);
    height: var(--line-height);
    border-radius: 50vh;
    // border: solid 1px blue;
    background: var(--off-color);
    margin-left: 5px;
    transition: .1s ease-in-out;

    &:before {
      content: '';
      position: absolute;
      top: var(--dot-offset);
      left: 0px;
      bottom: var(--dot-offset);
      width: var(--dot-size);
      background: var(--dot-color);
      border-radius: 50vh;
      transition: .1s ease-in-out;
    }
  }

  &.on {
    .toggle-btn__btn {
      background: var(--on-color);

      &:before {
        background: var(--on-dot-color);
        transform: translateX(100%);
      }
    }
  }

  &.disabled {
    opacity: .6;
    cursor: default;
  }
}
