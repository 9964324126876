.inbox {
  // min-height: 100%;
  padding: 20px;

  &__content {
    display: flex;
    gap: 20px;
  }

  &__menu {
    width: 300px;
    padding: 10px 0;

    ul {
      display: flex;
      flex-direction: column;
      gap: 5px;
    }

    li {
      padding: 10px;
      background: var(--darker);
      color: white;
      border-radius: 2px;
      cursor: pointer;

      &:hover {
        background: var(--dark2);
      }
    }
  }

  &__mail {
    flex: 1;
    padding: 10px 0;

    &__item {
      display: flex;

      padding: 10px;

      background: var(--lighter);
      color: black;
      margin: 1px;
      border-radius: 2px;

      .name {
        width: 250px;
      }

      .subject {
        flex: 1;
      }

      &.unread {
        font-weight: bold;
        background: var(--lightest);
      }
    }

    .table__header th {
      padding: 5px 10px;
    }

    .table td .cell {
      padding: 10px;
    }

    .unread {
      font-weight: bold;
    }

    .read {
      // --table-bg: var(--lightest);
      color: var(--dark);
    }
  }
}
