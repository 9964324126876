.main-nav {
  display: flex;
  flex: 1;
  margin: 0 1em;
  padding: 0;
  height: 100%;

  &__create-account {
    cursor: pointer;
    background: var(--warning);
    padding: 5px 10px;
    border-radius: 50vh;
    color: var(--darker);
  }

  li {
    display: flex;
    align-items: center;
    padding: 5px 10px;
    // background: blue;
    text-transform: uppercase;

    a {
      color: white;
      text-decoration: none;
      border-bottom: 3px solid transparent;
      padding-top: 3px;
    }

    a:after {
      background: var(--warning);
      color: black;
      left: 100%;
      right: auto;
      animation: notificationFlash 0.3s;
    }

    &.active {
      a {
        border-bottom: 3px solid white;
      }
    }
  }
}
