.add-comment {
  padding: 1em .5em;

  &__user {
    display: flex;
    align-items: flex-start;
    gap: 10px;
    padding-left: 20px;
    margin-bottom: -20px;
    position: relative;
    z-index: 1;

    .desc {
      position: absolute;
      left: 20px;
      right: 20px;
      padding-left: 50px;
      height: 20px;
      margin-top: 4px;
      background: #adc0d599;
      color: var(--dark);
      z-index: -1;
      backdrop-filter: blur(4px);
    }
  }

  &__content {
    textarea {
      width: 100%;
      height: 150px;
      padding: 1em;
      padding-top: 2em;
      background: var(--lighter);
      border-radius: .5em;
    }
  }
}
