.info-box {
  --corner-bg: var(--primary-btn);
  --bg: none;
  color: black;
  margin-top: 1em;
  background: var(--bg);
  border-radius: 4px;

  &.error {
    --corner-bg: var(--error);
  }

  &.warning {
    --corner-bg: var(--dark-orange);
  }

  &.success {
    --corner-bg: var(--success);
  }

  &.draft {
    --corner-bg: var(--lighter);
  }

  .desc {
    color: var(--dark2);
  }

  .rotate-right {
    transform: rotate(90deg);
  }

  .doc {
    display: flex;
    align-items: center;
    gap: 0.5em;

    &__icon {
      background: #e9e8e582;
      padding: 1em 1.5em;
      display: flex;
      border-radius: 0.5em;
    }

    svg {
      color: var(--medium);
    }

    &__label {
      margin: 0 0.5em;
      font-size: var(--text-medium);
      line-height: 1.5;
      font-size: 23px;
      font-weight: bold;

      .desc {
        color: var(--dark2);
        font-size: var(--text);

        label {
          color: var(--dark);
          padding: 0 5px;
          font-weight: normal;
        }
      }
    }
  }

  .positions {
    display: grid;
    grid-template-columns: 1fr 1fr;
    margin-bottom: 2em;
    margin: 0 1em 2em;
    gap: 2em;

    &__switch {
      position: absolute;
      border-radius: 50vh;
      background: var(--lighter);
      color: var(--darker);
      margin-left: -38px;
      margin-top: 105px;
      height: 65px;
      width: 65px;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      transition: 0.3s;
      cursor: pointer;
      z-index: 2;

      i {
        transition: 0.6s;
      }

      &:hover {
        box-shadow: 0 0 10px #888;

        i {
          transform: rotateY(180deg);
          color: var(--darker);
        }
      }

      &:active {
        opacity: 0.5;
      }

      span {
        font-size: var(--text-small);
      }

      svg {
        display: block;
      }
    }

    &__pos {
      display: flex;
      gap: 0.5em;
      align-items: center;
      padding: 0.5em 0;

      time {
        font-weight: 300;
      }
    }

    &__label {
      margin-bottom: 1em;
    }

    &__header {
      padding: 1em 0;

      label {
        background: var(--medium);
        padding: 0px 8px;
        font-size: var(--text-small);
        border-radius: 50vh;
        color: white;
        white-space: nowrap;

        &.cp {
          color: white;
          background: var(--dark-orange);
        }
      }
    }

    &__single {
      padding: 1.5em 0;
      margin-top: 2em;
      color: var(--dark2);
      font-weight: 500;
    }

    &__content {
      display: flex;
      flex-direction: column;
      flex: 1;
    }

    &__aside {
      .turn {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin: 5px;
        color: var(--success);

        .no-turn,
        .is-turn {
          opacity: 1;
          display: flex;
          align-items: center;
          font-size: var(--text-small);
          text-transform: uppercase;
          font-weight: bold;

          svg {
            margin-left: 5px;
          }
        }

        .no-turn {
          color: var(--warning);
        }
      }
    }

    .right {
      .positions__single,
      .positions__header,
      .positions__pos,
      .positions__user__card,
      .positions__user__info {
        flex-direction: row-reverse;
        text-align: right;
      }
    }

    &__party {
      font-size: var(--text-medium);
      flex: 1;
      border-radius: 5px;
      min-height: 150px;
      display: flex;
      flex-direction: column;
      // padding: 0 1em;

      &.isTurn {
        opacity: 1;
      }

      &.my {
        color: black;
        .desc {
          color: var(--dark);
        }
      }
    }

    &__user {
      position: relative;
      // padding: 0 .5em;
      border-radius: 5px;
      flex: 1;

      &__header {
        margin-bottom: 10px;
        color: var(--dark);
      }

      &__add {
        padding: 10px 10px 20px;
      }

      &__card {
        display: flex;
        align-items: center;
        margin-bottom: 0.5em;
        gap: 0.5em;
        padding: 1em;
        border-radius: 3px;
        // background: var(--grey-light);
        background: white;

        .--edit-icon {
          color: var(--dark);
          svg {
            display: block;
          }
        }

        .--title {
          flex: 1;
          // padding: 0 10px;
        }
      }

      &__info {
        font-size: var(--text-small);
        display: flex;
        justify-content: flex-start;
        padding: 10px;

        time {
          margin: 0 5px;
        }
      }

      &__stats {
        font-size: var(--text-small);
        li {
          margin: 5px 0;
          color: #999;
          label {
            color: black;
          }
        }
      }
    }

    &__signatories {
      h4 {
        margin: 0.5em;
        display: flex;
        align-items: center;
        gap: 1em;
      }

      &__behalf {
        // border: solid 1px red;
        padding: 5px;
        background: var(--lightest);
        border-radius: 5px;
        margin: 5px 0;
        // font-size: var(--text);

        li {
          // margin: .5em 5px .5em 2em;
          // list-style: disc;
          color: var(--darker);
          display: flex;
          align-items: center;
          gap: 0.5em;

          .--email {
            font-size: var(--text-small);
            color: var(--medium);
          }
        }

        &.cp {
          background: var(--cp-bg);
        }

        .signature__header {
          color: var(--dark);
        }

        .--label {
          color: black;
          display: flex;
          align-items: center;
          justify-content: space-between;
          margin: 5px;
        }
      }
    }
  }
}
