.document-edit__aside .positions {
  background: white;
  box-shadow: 0px 4px 32px rgba(0, 0, 0, 0.15);
  padding: 1em;
  border-radius: 3px;

  header {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  h2 {
    padding: .5em 0;
  }

  &__edit-btn {
    background: none;
    color: var(--dark);
    cursor: pointer;
    gap: .5em;

    svg {
      height: 1.5em;
      width: 1.5em;
    }

    &:hover {
      color: var(--dark2);
    }

    &[disabled] {
      cursor: default;
      &:hover {
        color: var(--dark);
      }
    }
  }

  &__view {
    display: flex;
    gap: 1em;
  }

  &__item {
    flex: 1;
    // border: solid 1px red;
    font-size: 1.6em;
    font-weight: 300;
  }

  .form-item__actions {
    display: flex;
  }

  &.elections .form-input {
    margin: 0;
  }
}
