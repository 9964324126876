.switch-fake-users {
  min-width: 720px;
}

.fake-users {
  margin: 20px 0 0;

  &__company {
    display: flex;
    align-items: center;
    font-size: var(--text-big);
    color: var(--my-party);

    .user-card__avatar {
      margin-right: 10px;
    }
  }
}

.logged-in {
  // color: var(--success);
  background: var(--success);
  border-radius: 50vh;
  padding: 3px 10px;
  color: white;
  display: inline-flex;
  font-size: var(--text-small);
  white-space: nowrap;
}
