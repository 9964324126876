.fel {
  margin: 5px 0;
  border-radius: 3px;
  position: relative;

  svg {
    display: block;
  }

  .drag-handle {
    cursor: pointer;
    opacity: 0.2;
  }

  [count] {
    display: flex;
    align-items: center;
    justify-content: center;
    &:before {
      content: attr(count);
      background: var(--success);
      color: white;
      min-width: 1rem;
      border-radius: 50vh;
      display: flex;
      font-size: 11px;
      align-items: center;
      justify-content: center;
    }
  }

  [count='0']:before {
    background: var(--grey);
    content: '?';
  }

  &__title {
    display: flex;
    align-items: center;
    gap: 1em;
    padding: 0.5em 0;
    margin-top: 1em;
    color: var(--primary);

    h2 {
      flex: 1;
    }
  }

  &__content {
    // border: solid 1px #ddd;
    background-color: white;
    border-radius: 3px;
    padding: 0.75em;
    transition: 0.3s;

    &:focus {
      box-shadow: 0 0 5px var(--primary);
    }
  }

  &__header {
    display: flex;
    align-items: center;
    gap: 0.5em;

    h4 {
      flex: 1;
      font-weight: 500;
    }
  }

  &__settings {
    color: var(--medium);
    justify-self: flex-end;
    font-size: 11px;

    span {
      color: #aaa;
    }

    svg {
      height: 20px;
      width: 20px;
    }
  }

  &__options {
    color: #999;
    margin-left: 3em;
    margin-top: 0.5em;

    li {
      display: flex;
      gap: 0.5em;
      align-items: center;

      svg {
        height: 1em;
        width: 1em;
      }
    }
  }

  &__remove {
    position: absolute;

    background: #00000085;
    color: white;
    inset: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 3px;
    gap: 1em;

    &__actions {
      display: flex;
      align-items: center;
    }
  }

  &__edit {
    padding: 0.5em 0;
    border: solid 1px #ddd;
    margin: 0px 0.5em 0.5em 0.5em;
    background: #fdfdfd;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    position: relative;
    border-top: solid 1px #eee;

    animation: slideIn 0.15s linear;

    form {
      // border: solid 1px red;
      display: flex;
      flex-direction: column;
      gap: 0.5em;
    }

    .form-item__content {
      display: flex;
      flex-wrap: wrap;
    }

    .form-label {
      min-width: 80px;
    }

    .dropdown__item__btn {
      padding: 0.25em 0.5em;
    }
  }

  &.--group {
    & > .fel__title {
      margin-right: 1em;
    }
    & > .fel__children {
      margin: 0;
      padding-bottom: 1px;
      position: relative;
      min-height: 3em;

      &:before {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        height: 3em;
        border-radius: 5px;
      }
    }

    &.empty > .fel__children:before {
      background: var(--grey);
    }
  }

  &.--array {
    .fel__settings svg {
      color: var(--inviting);
    }

    & > .fel__children {
      margin: 0 0.5em;
    }
  }

  &.--section {
    & > .fel__content {
      background: none;
    }

    & > .fel__children {
      border-radius: 5px;
      background-color: var(--grey);
      min-height: 4em;
      padding-bottom: 1em;
    }
  }

  &__columns {
    & > .fel__content {
      background: none;
      border: none;
      padding-left: 2em;
    }
    & > .fel__children {
      margin: 0;
      display: flex;
      gap: 0.5em;

      // border: solid 1px red;
      min-height: 4em;
      position: relative;

      &:before,
      &:after {
        z-index: -1;
        position: absolute;
        left: 0;
        top: 0;
        right: calc(50% + 3px);
        bottom: 0;
        background: var(--grey);
        border-radius: 3px;
        color: #999;
        display: flex;
        align-items: center;
        justify-content: center;
        font-size: 11px;
      }

      &:after {
        right: 0;
        left: calc(50% + 3px);
      }

      & > * {
        flex: 0;
        flex-basis: 50%;
      }
    }
  }

  &.cols-0 > .fel__children {
    &:before,
    &:after {
      content: 'Empty column';
    }
  }

  &.cols-1 > .fel__children {
    &:after {
      content: 'Empty column';
    }
  }

  .form-item__actions {
    display: flex;
    align-items: center;
  }
}

.ghost > .fel {
  border: solid 1px var(--green);
  box-shadow: 0 0 5px 0 var(--green);
}

@keyframes slideIn {
  from {
    transform: translateY(-30px) scale(0.5);
    opacity: 0;
    z-index: -1;
  }

  to {
    opacity: 1;
    transform: translateY(0) scale(1);
  }
}
