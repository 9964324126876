.grid__row {
  background: white;
  &.cp-turn {
    background: #f3f3f3;
  }
}

.neg-item {
  padding: 1em;
  // background: var(--lightest);
  // background-color: #fffcff;
  // background: white;
  border-radius: .25em;
  display: flex;
  flex-direction: column;
  gap: .5em;

  border-left: solid 4px var(--warning);
  box-shadow: 0 0 5px -1px var(--medium);

  &.--new,
  &.--updated {
    padding-top: 2em;
    position: relative;

    &:before {
      content: "NEW!";
      position: absolute;
      top: 0;
      left: 0;
      font-size: 10px;
      background: var(--success);
      padding: 0 3em;
      border-bottom-right-radius: .5rem;
      // color: white;
    }
  }

  &.--updated:before {
    content: 'UPDATED!';
    background: var(--medium);
    color: white;
  }

  &.completed {
    opacity: 1;
    border-left: none;
    background: white;
  }

  &.isTurn {
    opacity: 1;
    border-left-color: var(--success);
  }

  &:hover {
    box-shadow: 1px 1px 10px 0 var(--dark);
  }

  .user {
    display: flex;
    align-items: center;
    gap: 1em;
    border-radius: 3px;
    font-weight: bold;
    color: var(--dark);
    margin: .5em 0;
    flex-direction: row-reverse;

    img {
      width: 30px;
    }
  }

  &__title {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    gap: 1em;

    .version {
      white-space: nowrap;
    }
  }

  &__sub-title {
    display: flex;
    align-items: center;
    gap: 1em;

    .updated,
    .new {
      // background: var(--error);
      color: var(--error);
      // border: solid 1px currentColor;

      background: #e8533d3f;
      // color: white;
      padding: 0 1em;
      border-radius: 50vh;
      font-size: 10px;
      text-transform: uppercase;
      display: flex;
      align-items: center;
      gap: .5em;
      font-weight: normal;

      svg {
        height: 14px;
        width: 14px;
        display: block;
      }
    }

    .updated {
      color: var(--primary);
    }
  }

  &__info {
    display: flex;
    gap: 1em;
    font-size: 10px;
    font-weight: 500;
    color: #666;

    li {
      display: flex;
      align-items: center;
      gap: .75em;
      // background: var(--light-grey);
      padding: .25em .5em;
      border-radius: 3px;
      border: solid 1px var(--light-blue);

      span {
        color: #ccc;
      }
    }

    svg {
      width: 14px;
      height: 14px;
      display: block;
      color: var(--medium);
    }

    .owner {
      svg {
        color: var(--warning);
      }

      &.sender {
        svg {
          color: var(--success);
        }
      }
    }

    .--my-turn svg {
      color: var(--success);
    }

    .--cp-turn svg {
      color: var(--warning);
    }
  }

  &__stats {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: .5em;
    // border-top: solid 1px #eee;
    padding-top: .5em;
    font-weight: 300;

    .desc {
      font-weight: 300;
    }

    .progress-line {
      flex: 0;
      width: 100px;

      .progress-line__step {
        height: 8px;
      }
    }
  }

  &__updated {
    color: var(--dark);
    text-align: right;
  }

  dl {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    font-weight: normal;
    margin: .5em 0;

    font-size: var(--text-small);
    dt {
      width: 75px;
    }

    dd {
      color: var(--medium);
      flex: 1;
      margin: 0;
    }
  }
}
