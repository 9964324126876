.pre-load {
  padding: 100px;
  height: 100%;
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.pre-update {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: #ffffff99;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  border-radius: 5px;
  animation: fadein ease-in 1s;

  &__fixed {
    position: fixed;
    top: 50%;
  }
}

.main > .pre-load {
  min-height: 50vh;
  max-height: 90vh;
}
