.create-account {
  min-width: 800px;
  color: white;

  &__actions {
    margin: 20px 0 0;
    display: flex;
    justify-content: space-between;
    align-items: center;

    // border: solid 1px red;
  }

  &__main-btn {
    display: flex;
    align-items: center;
    gap: 1em;
    color: var(--lightest);

    @keyframes warningFlash {
      from {
        transform: scale(1.2);
        opacity: 0;
      }
      to {
        transform: scale(1);
        opacity: 1;
      }
    }
  }

  .warning-msg {
    display: flex;
    align-items: center;
    gap: 10px;

    b {
      background: var(--dark-orange);
      font-size: var(--text-big);
      color: var(--lightest);
      width: 36px;
      // height: 24px;
      animation: warningFlash .2s;

      padding: 3px 9px 3px 8px;
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 50vh;
    }
  }

  .info-line {
    margin: 0 20px;
    padding-top: 10px;
    // border: solid 1px red;

    a {
      color: var(--dark-link);
      text-decoration: underline;
    }

    .required {
      color: var(--error);
    }
  }

  &__desc {
    line-height: 1.5;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 1em;
  }

  &__email {
    padding: 10px;
    font-size: var(--text-big);
    color: var(--dark-orange);
  }

  .form-columns {
    gap: 40px;
  }

  .radio__children {
    border-left: solid 1px var(--lighter);
    margin-left: 15px;
    padding-left: 15px;
  }

  .skip.btn {
    color: var(--lightest);
  }
}
