.user-card {
  --inviting-color: var(--dark-orange);
  --bg: var(--light-grey);
  --expand-bg: var(--light-blue);

  color: black;
  border-radius: 5px;
  margin: 10px 0;
  position: relative;
  background: var(--expand-bg);

  &.--company {
    --bg: var(--color3);
    // color: black;
  }

  &.dark {
    --inviting-color: var(--warning);
    --bg: var(--color7);
    --expand-bg: var(--dark2);
    color: white;
    border-radius: 5px;

    &.--company {
      --bg: var(--darker);
    }
  }

  &.blank {
    --bg: none;
    --expand-bg: none;
    border: none;
    color: white;
    padding: 3px 5px;
    transition: 0.3s;
    border-radius: 5pc;
  }

  &.hover {
    cursor: pointer;
    &:hover {
      background: var(--dark);
    }
  }

  &__info {
    margin: 0 5px;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
  }

  &__view {
    flex: 1;
    display: flex;
    align-items: center;
    justify-items: flex-start;
    border-radius: 5px;
    background: var(--bg);
    // padding-right: 10px;
  }

  &__more-btn {
    .dropdown {
      padding: 0;
    }
  }

  &__edit {
    padding: 5px;
    display: flex;
    justify-content: space-between;

    & > div {
      display: flex;
      align-items: center;
      justify-content: flex-end;
      padding: 5px;
    }
  }

  &__remove {
    flex: 1;
  }

  &__delete {
    flex: 1;
    position: absolute;
    top: 0;
    left: 0;
    right: -20px;
    bottom: 0;
    // border: solid 1px red;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #000000bb;
    border-radius: 5px;
    color: white;
    font-size: 19px;

    h3 {
      color: var(--warning);
    }

    & > * {
      margin: 5px;
    }
  }

  &__title {
    flex: 1;
    padding: 0 5px;
    white-space: nowrap;

    i {
      color: var(--inviting-color);
    }
  }

  &__avatar {
    --avatar-size: 34px;
    --avatar-bg: var(--dark2);
    --avatar-color: white;

    display: flex;
    align-items: center;
    justify-content: center;
    margin: 3px;
    flex-shrink: 0;
    height: var(--avatar-size);
    width: var(--avatar-size);
    border-radius: 50vh;
    overflow: hidden;

    &.dark {
      --avatar-bg: var(--lighter);
      --avatar-color: black;
    }

    &.logo {
      background: white;
      // border-radius: 5px;
      width: 60px;
    }

    &.circle {
      width: auto;
    }

    img {
      height: var(--avatar-size);
      object-fit: contain;
      background: var(--lightest);

      &.--company-logo {
        background: white;
        width: 100%;
      }
    }

    .waiting-logo {
      background: var(--warning);
      border-radius: 50vh;
      color: black;
      display: flex;
      align-items: center;
      justify-content: center;
      height: var(--avatar-size);
      width: var(--avatar-size);

      svg {
        width: 20px;
        height: 20px;
      }
    }

    // &.dark {
    //   --avatar-bg: var(--medium);
    //   img {
    //     background: var(--lightest);
    //   }
    // }

    &.small {
      --avatar-size: 28px;
    }
    &.mini {
      --avatar-size: 20px;
    }

    &.medium {
      --avatar-size: 40px;
    }

    &.big {
      --avatar-size: 64px;
    }
  }

  &__letters {
    display: flex;
    align-items: center;
    justify-content: center;
    background: var(--avatar-bg);
    height: var(--avatar-size);
    width: var(--avatar-size);
    border-radius: 50%;
    padding-bottom: 2px;
    color: var(--avatar-color);
    font-size: calc(var(--avatar-size) / 2.5);
    font-weight: bold;
  }

  &__perm {
    font-size: 13px;
    color: var(--warning);
    padding: 0 5px;
  }

  &__children {
    margin: 0 2px;
  }

  &__delete {
    margin-right: 20px;
  }

  .tag {
    position: absolute;
    top: -9px;
    width: 100%;
    display: flex;
    justify-content: center;
    font-size: 8px;
    display: flex;
    text-transform: uppercase;
    color: black;

    &__item {
      border: solid 1px var(--color6);
      border-radius: 3px;
      padding: 0 10px;
      background: var(--my-party);
      margin: 0 3px;
      color: black;
      box-shadow: 1px 1px 3px 0 #666;

      &.cp {
        background: #f4d69f;
        // border-color: orange;
        color: black;
      }
    }
  }
}

// .company-card,
// .company-card.dark {
//   background: var(--color5);
// }
