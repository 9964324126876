.form-array {
  h4 {
    padding: .5rem;
    font-size: var(--text-big);
  }

  .array {
    &__item {
      display: flex;
      position: relative;
      align-items: flex-start;
      border-radius: 2px;

      &:nth-child(odd) {
        background: var(--light-grey);
      }

      .--nr {
        background: var(--dark);
        color: white;
        border-radius: 50vh;
        min-width: 25px;
        height: 25px;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .--form {
        flex: 1;
      }
    }

    &__btn {
      padding: 0;
      background: none;
      color: var(--error);

      &.--add {
        position: relative;
        color: var(--dark);
        top: 0;
        margin: .5em;
        border: solid 1px var(--dark);
        padding: 0 1em 0 .5em;
        border-radius: 50vh;
      }
    }
  }
}

.form-children {
  border: solid 1px red;
  margin: 5px;
  background: #ccc;
}
