.signature-block {
  margin-bottom: 10em;
  background: var(--light-grey);
  border-radius: 5px;
  border: solid 1px var(--lighter);
  padding: 1em;

  margin: 1em;

  &.executed {
    // background: none;
    border: none;
  }

  .execute-doc {
    &__actions {
      padding: 1em 0;
    }

    .btn {
      border: none;
    }

    .btn[disabled] {
      color: #666;
    }

    h2,
    h3 {
      padding-top: 1rem;
    }

    h2,
    h3,
    strong {
      color: var(--dark2);
    }

    ul li {
      list-style: disc outside;
      margin-left: 20px;
    }
  }
}
