.offline-signature {
  border: solid 1px var(--grey);
  border-radius: 5px;
  background: white;
  padding: .5em;
  color: black;
  display: flex;
  align-items: center;
  gap: 1em;
  min-width: 300px;
  max-width: 400px;
  line-height: 1.5;
  position: relative;

  h3.offline-signature__title {
    margin: 0;
  }
}
