:root {
  --primary: #247ba0;
  --secondary: #648c81;

  --cp-bg: #f1dfbe;
  --my-party: #bfdbe8;
  --cp-compare: linear-gradient(90deg, #eeafc9 0%, #fca7ab 100%);

  // --my-bg: #c4ddf340;
  --my-border: transparent;

  --matched-border: #2a921647;
  --read-only-border: #cccccc44;

  --darker: #1e2f41;
  --dark: #46678a;
  --dark2: #2e435d;

  --medium: #6284a8;
  --lighter: #adc0d5;
  --lightest: #edeff1;

  --dark-orange2: #e27100;
  --dark-orange: #e98400;

  --grey-light: #fbf9fa;
  --grey: #e9e8e5;
  --grey-medium: #a9b6c2;
  --grey-dark: #515f6d;

  --light-grey: var(--grey-light);
  --light-green: #e3eae8;

  --light-blue: #dee9f1;
  --light-blue2: #e7ecf0;
  --blue: #46678a;

  // --header: #203042;
  --footer: #1e2f41eb;
  --header: var(--footer);

  --primary-btn: #007bff;
  --link: #5fc4df;
  --dark-link: var(--primary-btn);

  --success2: #2a9216;
  --warning2: #f7b847;

  --error2: #e8533d;
  --error: #b31412;

  --success: #73ccac;
  --success-dark: #40b088;

  --draft: var(--grey-medium);
  --active: var(--link);
  --warning: #ffb86f;
  --inviting: #c273cf;
  --execution: #6c87cd;
  --completed: var(--success);

  --smart-id-color: #00afaa;

  --text: 13px;
  --text-small: 11px;
  --text-medium: 15px;
  --text-big: 19px;
  --header-text: 13px;
}

html,
.document-preview {
  scroll-behavior: smooth;
}

@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@100;400;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Open+Sans');
@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,300;0,400;0,500;0,600;0,700;1,400&display=swap');

body {
  margin: 0;
  font-family: 'Poppins', 'Segoe UI', 'Arial', sans-serif;
  font-display: block;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-size: var(--text);
  min-height: 100vh;
}

* {
  box-sizing: border-box;
}

svg {
  display: flex;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
ul {
  padding: 0;
  margin: 0;
  list-style: none;

  & > li {
    list-style: none;
  }
}

button {
  background: #1973e7;
  color: white;
  border: none;
  border-radius: 5px;
  display: flex;
  align-items: center;
  font-family: 'Poppins', 'Segoe UI', sans-serif;

  // font-family: 'Roboto', 'Segoe UI', sans-serif;

  &:focus {
    outline: none;
  }
}

:focus {
  outline: none;
}

.ProseMirror,
select,
textarea,
input {
  border: #ccc 1px solid;
  font-size: var(--text);
  border-radius: 18px;

  padding: 10px;
  border: solid 1px var(--medium);
  // font-family: 'Roboto', 'Segoe UI', sans-serif;

  &:focus {
    outline: none;
    border-color: var(--primary);
    box-shadow: 0 0px 3px var(--header);

    &.error {
      border-color: var(--error);
    }
  }

  &::placeholder {
    color: var(--grey);
  }

  &.error {
    box-shadow: 0 0px 3px var(--error);
  }
}

option {
  font-size: 15px;
  background: white;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-weight: 600;
}

a {
  display: inline-flex;
  align-items: center;
  text-decoration: none;
  color: inherit;
}

p {
  white-space: initial;
}

time {
  display: flex;
  align-items: center;
  gap: 5px;
  color: var(--dark);
  font-size: var(--text-small);
}
