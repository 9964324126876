.filter {
  margin-top: 30px;

  &__header {
    display: flex;
    align-items: center;
    gap: .5em;
    position: absolute;
    margin-top: -36px;
    margin-left: .5em;
    cursor: pointer;

    z-index: 1;

    .button {
      display: flex;
      align-items: center;
      gap: .5em;
      background: var(--primary);
      color: white;
      border-radius: 50vh;
      padding: .2em 1em;
      position: sticky;
      top: 50px;
    }
  }

  //---------------------------------------------------------------
  // Legend
  .legend {
    color: white;
    position: absolute;
    right: 1em;
    // top: -90px;
    bottom: 100%;
    margin-bottom: -30px;
    z-index: 1;

    &__item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      gap: 2em;
      margin: 1em 0;

      &.empty {
        opacity: .4;
      }
    }

    &__count {
      display: flex;
      align-items: center;
    }
  }

  //---------------------------------------------------------------

  &__reset {
    margin: 0 .5em;

    button {
      background: none;
      border: solid 1px var(--primary-btn);
      padding: 2px 1em;

      svg {
        margin-left: -4px;
        margin-right: 5px;
        width: 16px;
        height: 16px;
      }
    }
  }

  &__content {
    padding-top: 5em;
    margin-right: 1em;
    background: #fcfcfc;
  }

  &__group {
    padding: 0.5em 0;
    margin-bottom: 1em;
    margin-top: 5px;
    border-radius: var(--table-radius);
    min-width: 250px;
    max-width: 350px;
    margin-right: 1em;

    h3 {
      margin: 5px;
      padding: 5px;
      padding-top: 0;
      border-bottom: dashed 1px #999;
      display: flex;
      align-items: center;
      gap: .5em;
      cursor: pointer;
      color: var(--primary);

      svg {
        transition: .2s;
      }

      &.open svg {
        transform: rotate(90deg);
      }
    }
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 1em;
    padding: .25em;
    margin: 5px;
    cursor: pointer;
    border-radius: var(--table-radius);
    min-height: 40px;

    &__checkbox {
      color: var(--primary);
      svg {
        display: flex;
      }
    }

    &.active,
    &.selected {
      background: var(--lightest);
    }

    &:hover {
      background: var(--light-blue);
    }

    &.empty {
      opacity: .2;
      cursor: default;

      &:hover {
        background: none;
      }
    }

    .desc {
      color: var(--lighter);
    }

    .label-active {
      background: var(--my-party);
    }

    .state {
      font-size: var(--text);
      display: inline-flex;
    }

    &__view {
      flex: 1;
    }

    &__count {
      font-size: var(--text-small);
      display: flex;
      align-items: flex-start;
      background: var(--grey);
      border-radius: 3px;
      color: var(--medium);
      padding: 0 .5em;
      gap: .25em;
      font-weight: 600;

      .--available,
      .--total {
        border-radius: 50vh;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .--available {
        color: var(--dark-orange2);
        display: flex;

        &:after {
          content: '/';
          margin-left: .25em;
          font-weight: normal;
          color: var(--dark);
        }
      }
    }
  }

  &__actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: .5em;
    color: #aaa;

    button {
      background: none;
      color: var(--link);
      text-decoration: underline;
      cursor: pointer;
    }
  }
}

.item {
  display: flex;
  flex-direction: column;
  gap: 5px;

  &__flex {
    display: flex;
    align-items: center;
    gap: .5em;
  }
}
