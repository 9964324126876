.dashboard {
  flex: 1;
  height: 100%;
  display: flex;
  margin: auto;

  h1 {
    font-size: 30px;
    text-align: center;
    margin: 3em 0;
  }

  .intro {
    flex: 1;
    min-width: 400px;
    padding: 1em;

    &__container {
      max-width: 700px;
      margin: auto;
    }

    &__step {
      display: flex;
      align-items: flex-start;
      gap: 3rem;
      margin: 1rem;
      // border: solid 1px #ccc;
      border-radius: .5em;
      padding: 0.5rem;

      ul {
        margin-top: 1rem;
        padding: 0 1rem;
      }
      li {
        list-style: disc;
        margin: .5em 0;

        & > ul {
          color: var(--medium);
          font-size: 12px;
          li {
            list-style: circle;
          }
        }
      }

      svg {
        // color: #585759;
        color: var(--dark);
      }
    }

    &__text {
      flex: 1;
    }

    &__nr {
      font-size: 44px;
      width: 36px;
      display: flex;
      justify-content: center;
      color: #585759;
    }
  }

  &__actions {
    height: 100%;
    background: var(--primary);
    padding: 0 3em;
    flex: 1;
    max-width: 700px;

    h1 {
      color: white;
    }
  }
}
