.conditions.nested {
  &.selected {
    background: var(--orange);
    color: var(--darker);
  }

  .nested__tabs {
    margin-left: 2em;
    position: relative;

    visibility: hidden;

    &.active,
    &.selected {
      visibility: visible;
    }

    .tab {
      &:hover {
        background: inherit;
        color: inherit;
        box-shadow: none;
      }
    }

    &.selected {
      .tab {
        background: var(--green);
        white-space: nowrap;
        color: var(--dark2);
      }
    }

    &:before {
      content: '=';
      position: absolute;
      color: white;
      left: -2em;
      background: var(--dark2);
      padding: .25em .5em;
      border-radius: 5px;
    }
  }
}

.nested {
  &__label {
    display: flex;
    gap: .25em;
    align-items: center;
    // padding-right: .5em;
    padding: 3px .5em;
    height: 30px;
    white-space: nowrap;
    &.--if {
      min-width: 70px;
    }
  }

  &.isSignature &__label {
    svg {
      height: 18px;
      width: 24px;
      display: block;
    }
  }

  &__node-name {
    color: white;
  }

  &__tabs {
    display: inline-flex;
    gap: 2px;
    background: var(--dark2);
    box-shadow: 0 0 10px 0 var(--dark);
    border-radius: 5px;
    padding: 3px;

    .tab {
      padding: .5em;
      height: 30px;
      border-radius: 3px;
      display: flex;
      gap: .5em;
      align-items: center;
      justify-content: center;
      background: none;
      cursor: default;

      &.active {
        // box-shadow: 0 0 5px 0 #ccc;
        background: var(--grey);
        color: var(--dark);
        // color: white;
      }

      svg {
        height: 24px;
        width: 24px;
        display: block;
      }

      &.signature svg {
        height: 18px;
        width: 18px;
      }

      &:hover {
        box-shadow: 0 0 5px 0 #ccc;
        background: var(--light-grey);
        color: #666;
      }
    }
  }

  &__content {
    background: var(--dark);
    box-shadow: 0 0 10px 0 var(--dark);
    border-radius: 5px;
    min-width: 200px;
    max-height: 350px;
    overflow: auto;
  }

  &__dropdown {
    position: absolute;
    color: white;
    margin: 0;
    margin-left: .5em;
    padding: 0;
    top: -3px;
    left: 100%;
    display: flex;
    flex-direction: column;
    gap: .5em;
    align-items: flex-start;

    &.isAtBottom {
      top: auto;
      bottom: -3px;
      flex-direction: column-reverse;
    }

    li {
      list-style: none;
      border-radius: 5px;
      color: #ccc;

      .label {
        padding: .5em;
        white-space: nowrap;
        display: flex;
        align-items: center;
        gap: .5em;
      }

      ul {
        padding: 0 0 0 1em;
      }

      .--columns {
        ul {
          display: flex;
          flex-direction: column;
          gap: .5em;
        }
      }

      &.--group {
        & > .label {
          background: var(--grey);
          font-size: 1em;
          // color: white;
          color: black;
        }

        & > ul {
          padding: .5em;
          display: flex;
          flex-direction: column;
          gap: .5em;
        }
      }

      &.--section {
        // border: solid 1px var(--grey-medium);
        background: #37516f;
      }

      &.active {
        border: solid 1px var(--orange);
        .label {
          background: var(--orange);
          color: black;
          border-radius: 3px;
        }

        .selected {
          background: var(--success);
          color: var(--dark2);

          &:hover {
            background: var(--success);
          }
        }
      }

      .isInput {
        color: white;

        & > .label {
          cursor: pointer;
        }

        &:hover {
          background: var(--dark2);
        }

        &.active {
          border: solid 1px var(--green);
          .label {
            background: var(--green);
            color: black;
            border-radius: 3px;
          }
        }
      }
    }
  }

  &__form {
    padding: 0 1em;
    .form-input {
      input,
      select {
        padding: .25em .5em;
      }
    }

    .form-label {
      padding: 0;
      padding-bottom: 5px;
      font-size: 11px;
    }

    .form-item__actions {
      display: flex;
    }

    .form-item .checkbox__item {
      padding: 0;
      margin: 0;
      &:focus {
        box-shadow: none;
      }
    }
  }

  &__add-new {
    display: flex;
    align-items: center;
    color: var(--green);
    justify-content: flex-start;
    padding: .5em;

    button {
      padding: 0 .5em;
      font-size: 11px;
      font-weight: 300;
      background: none;
      color: var(--green);
      border-color: var(--green);
      gap: .25em;
      border: solid 1px var(--green);
      cursor: pointer;
      transition: .1s;

      svg {
        height: 1em;
        width: 1em;
        display: block;
      }

      &:hover {
        background: var(--success);
        color: white;
      }
    }
  }

  &__signature {
    padding: 3px;
    min-width: 300px;

    &__header {
      font-weight: 600;
      padding: .5em 0;
      margin-bottom: .5em;
    }

    &__list {
      display: flex;
      gap: .5em;
    }

    &__item {
      border-radius: 3px;
      border: solid 1px var(--light-grey);
      flex: 1;
      padding: 1em;
      position: relative;

      &.active {
        background: var(--light-grey);
        color: var(--dark);
        border-radius: 3px 3px 0 0;

        &:after {
          position: absolute;
          content: '';
          left: -1px;
          top: 100%;
          right: -1px;
          height: 8px;
          margin-top: -1px;
          background: var(--light-grey);
          // border-left: solid 1px #ccc;
          // border-right: solid 1px #ccc;
        }
      }
    }

    &__properties {
      margin-top: 3px;
      background: var(--light-grey);
      color: var(--dark);
      border: solid 1px var(--light-grey);
      border-radius: 3px;
      padding: .5em;
    }
  }

  ul.nested__options {
    // border: solid 1px red;
    margin: .5em 2em;
    list-style: circle;

    li {
      margin: .25em 0;
      padding: .25em .5em;
      // padding-left: 1em;
      list-style: circle;
      cursor: pointer;

      &:hover {
        background: var(--dark2);
      }
    }
  }
}
