.parties {
  display: flex;
  .form-item .checkbox {
    --compare-text: white;

    &__item {
      margin: 0;

      &:focus {
        box-shadow: none;
      }
    }
  }

  &__list {
    flex: 1;
    margin-right: 20px;
  }

  &__add {
    flex: 1;
    .form-item {
      border: none;
      margin: 5px 0;

      button {
        align-self: flex-start;
      }
    }
  }

  &__content {
    // padding: 10px 0;
  }

  &__input {
    display: flex;
    align-items: flex-end;

    &__email {
      flex: 1;
    }
  }

  &__send {
    margin: 5px 0;
  }

  &__fixed {
    position: sticky;
    top: 0;
  }
}
