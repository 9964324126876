.settings-tab {
  display: flex;
  height: 100%;

  & > div {
    flex: 1;
    // border: solid 1px red;
  }

  &__delete {
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }
}
