@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

@keyframes fadeInHalf {
  from {
    opacity: 0;
    transform: scale(0, 0);
  }
  to {
    opacity: .6;
    transform: scale(1, 1);
  }
}

@keyframes slideIn {
  from {
    transform: translateY(-100%);
  }
  to {
    transform: translateY(0);
  }
}

@keyframes slideInLeft {
  from {
    transform: translateX(-100%);
    opacity: 0;
  }
  to {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slidein {
  from {
    opacity: 0;
    transform: translateY(calc(-100% + 60px));
  }
  to {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
    display: flex;
  }
  99% {
    opacity: 0;
    display: flex;
  }
  // 100% {
  //   display: none;
  // }
}

@keyframes pulse {
  from {
    opacity: 0;
  }
  50% {
    opacity: .6;
  }
  to {
    opacity: 0;
  }
}
