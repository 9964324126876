.toolbar .sub-menu {
  left: 0;
  right: 0;
  z-index: 10;
  // margin: 0 2%;
  display: flex;
  background: var(--dark);
  color: white;
  h3 {
    color: var(--warning);
  }

  section {
    display: flex;
    align-items: flex-start;

    article {
      flex: 1;
    }
  }

  &__section {
    margin: 10px 0;
  }

  &__tabs {
    background: var(--darker);
    padding-bottom: 15px;
    font-size: var(--text-medium);

    &__icon {
      width: 24px;
      height: 24px;
      padding: 3px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: white;
      color: black;
      border-radius: 50vh;
      margin-right: 15px;
    }

    ul {
      flex-direction: column;
      align-items: stretch;
      height: auto;
      padding: 0;
      width: 220px;

      li {
        padding: 10px 15px;
        cursor: pointer;
        transition: 0.1s;
        display: flex;
        align-items: center;
        white-space: nowrap;
        user-select: none;

        &.active {
          background: var(--dark);
        }

        &:hover {
          background: var(--dark);
        }
      }
    }
  }

  &__content {
    padding: 10px 20px;
    flex: 1;
    height: 250px;
    overflow: auto;
  }

  .info {
    background: rgba(var(--darker-rgb), .75);
    border-radius: 10px;
    margin: 10px;
    padding: 5px;

    &__item {
      padding: 5px;
      color: var(--warning);

      label {
        font-weight: bold;
        padding: 0 10px;
        color: white;
      }

      i {
        color: var(--lighter);
      }
    }
    p {
      color: white;
      margin-left: 15px;
    }

    &__edit-btn {
      float: right;
    }
  }

  .download {
    padding: 10px;
    flex: 0;

    &__actions {
      display: flex;
      gap: 20px;
      margin: 10px 0;
      align-items: flex-start;
    }

    &__icon,
    &__audit {
      width: 100px;
      height: 100px;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      gap: 5px;
      padding: 12px;
      border-radius: 10px;
      background: var(--dark2);
      cursor: pointer;

      svg {
        height: 41px;
      }

      &__label {
        text-align: center;
      }

      &[disabled] {
        opacity: .5;
      }
    }

    &__audit {
      flex-direction: row;
      height: auto;
      margin-top: 1em;
      padding: 5px;
      font-size: var(--text-small);
      color: #999;

      svg {
        height: 24px;
        // color: white;
      }
    }
  }
}

// .download {
//   padding: 10px;
//   flex: 0;

//   &__header {
//     font-weight: normal;
//     color: var(--lighter);
//   }

//   &__actions {
//     display: flex;
//     gap: 20px;
//     margin: 10px 0;
//   }

//   &__icon {
//     width: 100px;
//     display: flex;
//     align-items: center;
//     justify-content: center;
//     flex-direction: column;
//     gap: 5px;
//     padding: 12px;
//     border-radius: 10px;
//     background: var(--medium);
//     cursor: pointer;
//   }
// }

.info-table {
  width: 100%;
  padding: 10px;

  td {
    // width: 100%;
  }

  th {
    width: 100px;
    text-align: right;
    padding: 0 10px;
    vertical-align: top;
  }
}
