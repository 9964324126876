.dd {
  border-radius: 5px;

  &.open {
    .toolbar__btn {
      background: var(--dark);
    }

    &:before {
      content: '';
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      cursor: default;
    }
  }

  &__save {
    margin-top: 1em;
    display: flex;
    gap: .5em;
    align-items: center;
  }

  &__box {
    position: absolute;
    background: #1e2f41f5;
    box-shadow: 1px 1px 15px var(--color7);
    color: white;
    margin-left: 2px;
    padding: 15px;
    border-radius: 10px;
    border-top-left-radius: 0;
    border-top-right-radius: 0;
    margin-top: 9px;

    h3 {
      font-size: var(--text-medium);
      padding: 0 5px;
    }
  }

  &__list {
    max-height: calc(100vh - 240px);
    overflow: auto;
  }

  &__wrapper {
    background: black;
    margin: 15px 5px;
    border-radius: 50vh;
    position: relative;
    display: flex;

    .--version {
      position: absolute;
      top: -3px;
      padding: 3px;
      padding-right: 4px;
      font-size: var(--text-medium);
      font-weight: bold;
      width: 34px;
      height: 34px;
      display: flex;
      align-items: center;
      justify-content: center;
      background: var(--medium);
      color: white;
      border-radius: 50vh;
      border: solid 3px white;
    }
  }

  &__item {
    background: var(--light-grey);
    color: black;
    display: flex;
    align-items: center;
    border-radius: 50vh;
    min-width: 300px;
    height: 34px;
    cursor: pointer;
    flex: 1;
    padding: 3px;
    padding-left: 40px;
    padding-right: 20px;

    &:hover {
      box-shadow: 0 0 10px 0 var(--grey);
      background: var(--grey);
    }

    &.selected {
      opacity: 0.5;
    }

    .--date {
      margin: 0 10px;

      .time {
        font-weight: bold;
        white-space: nowrap;
      }
      .action {
        color: var(--dark-orange);
        white-space: nowrap;
      }
    }

    .--user {
      flex: 1;
      .name,
      .desc {
        text-align: right;
      }
    }
  }
}
