.logo-editor {
  --bg-mask: #46678a99;

  &__content {
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    height: 500px;
    width: 800px;
  }

  &__image {
    img {
      cursor: move;
      height: auto;
      opacity: 0;
      transition: opacity .3s;

      &.--ready {
        opacity: 1;
      }
    }
  }

  &__crop {
    pointer-events: none;
    position: absolute;
    inset: 0;
    background: radial-gradient(circle at center, transparent 150px, var(--bg-mask) 150px, var(--bg-mask) 100%);
  }

  &__actions {
    display: flex;
    align-items: center;
    gap: .5em;
  }

  input[type="range"] {
    &:focus {
      box-shadow: none;
    }
    padding: 0;

    margin: 1em 0;
    width: 100%;
    transition: none;
  }

  canvas {
    border: solid 1px red;
  }
}
