.svg-line {
  position: relative;
  // padding-top: 3px;
  padding-left: 5px;
  pointer-events: none;

  &__box {
    position: absolute;
    margin-top: 3px;
    // border: solid 1px #ff000077;
    z-index: 1;
    // color: var(--dark2);
    // background: #ff000022;

    // .left {
    //   color: orange;
    // }
    // .top {
    //   color: blue;
    // }
  }

  pre {
    position: absolute;
  }

  svg {
    stroke-linecap: round;
    stroke-linejoin: round;
    fill: none;
    stroke: currentColor;
    stroke-width: 3px;
    stroke-dasharray: 5px;
    filter: drop-shadow(0 0 2px var(--darker));
    // opacity: .5;
  }
}
