.grid {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  gap: 1px;
  margin-top: 24px;

  &__header {
    padding: 1em;
    background-color: #fffcff;

    border-radius: 0.5em;
    margin: 0.5em;
    margin-bottom: 1em;
    display: flex;
    flex-direction: column;
    gap: 1em;
    position: sticky;
    top: 55px;
    z-index: 9;

    box-shadow: 5px 0 15px -5px var(--dark2);

    h3 {
      text-transform: uppercase;
      font-weight: 500;
    }

    .desc {
      display: flex;
      justify-content: space-between;
    }
  }

  &__row {
    display: block;
  }

  .table {
    display: flex;
    flex-direction: column;
    gap: 1em;

    padding: 0.5em;
    border-radius: 0.5em;
  }

  .table__col {
    display: block;
  }
}
