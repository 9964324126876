.form-label {
  font-weight: normal;
  display: flex;
  justify-content: center;
  gap: .5em;

  label {
    flex: 1;
  }

  &__state {
    width: 18px;
    height: 18px;
    // border: solid 1px red;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50vh;
    color: var(--error2);
    // color: white;
    padding: 2px;

    &.completed,
    &.isCompleted {
      color: var(--dark);
      padding: 0;
    }

    &.matched,
    &.isMatched {
      color: var(--success);
      padding: 0;
    }
  }

  .toggle-view2 {
    // display: flex;
    gap: 2px;
    padding: 1px;
    border-radius: 2px;

    li {
      border: solid 1px var(--lighter);
      background: white;
      padding: 5px;
      min-width: 50px;
      // font-size: var(--text-small);
      border-radius: 2px;
      display: flex;
      // margin: 5px 0;
      // flex-direction: column;
      margin-top: 5px;
      gap: 20px;
      justify-content: space-between;
      align-items: center;
      cursor: pointer;
      min-height: 35px;

      &:hover {
        box-shadow: 0 0 1px 1px var(--lighter);
      }

      svg {
        height: 16px;
        width: 16px;
      }

      &.active {
        background: var(--dark);
        color: white;
      }

      &.disabled {
        background: var(--grey);
        opacity: .5;
        cursor: not-allowed;
        &:hover {
          box-shadow: none;
        }
      }
    }
  }
}
