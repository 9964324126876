.admin-negotiations {
  small {
    color: var(--primary-btn);
  }

  // li {
  //   display: flex;
  //   align-items: center;
  //   justify-content: space-between;
  //   padding: 3px;
  //   // color: var(--error);

  //   &.isVisited {
  //     .party__user {
  //       color: var(--darker);
  //     }
  //   }
  // }

  .error .table__expand__btn .cell button {
    background: var(--error);
  }

  .sender {
    padding: 0 5px;
    color: var(--dark);
  }

  .receiver {
    // padding: 0 5px;
    color: var(--primary-btn);
  }

  .inviting {
    color: var(--dark-orange);
  }

  .isTurn {
    background: var(--light-green);

    .inviting {
      font-size: var(--text-medium);
      font-weight: bold;
    }
  }

  // .showLastMessage {
  //   border: solid 1px var(--warning);
  //   margin: 3px 0;
  //   border-radius: 2px;
  //   background: white;
  // }

  .last-message {
    font-size: var(--text-small);
    color: var(--dark2);

    label {
      color: var(--medium);
      font-weight: normal;
    }

    &__content {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }
  }

  .party {
    &__user {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 3px;

      flex: 1;
      color: #999;
      font-size: var(--text-small);

      &__actions {
        display: flex;
        align-items: center;
        gap: 10px;
      }

      &.isVisited {
        color: var(--darker);
      }

      .warning-icon {
        // border: solid 1px red;
        display: flex;
        color: var(--dark-orange);
        font-weight: bold;

        svg {
          width: 18px;
          height: 18px;
        }
      }

      .allowResend {
        background: var(--primary-btn);
        color: white;
        border-radius: 50vh;
        padding: 1px .5em;

        .desc {
          color: var(--lightest);
        }

        .warning-icon {
          color: var(--cp-bg);
        }
      }
    }
  }
}
