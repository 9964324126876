.party-line {
  display: flex;
  align-items: center;
  margin: 1em;
  gap: 1em;

  &__label {
    width: 130px;
  }

  &__stats {
    padding: .5em 1em;
    flex: 1;
    display: flex;
    flex-direction: column;
    gap: .5em;
  }

  .--all-done {
    display: flex;
    align-items: center;
    gap: .5em;
    padding: .5em 0;

    svg {
      color: var(--success);
    }
  }

  &__party {
    flex: 1;
    display: flex;
    align-items: center;
    gap: 1em;
    padding: .5em;
    border-radius: 50vh 0 0 50vh;
    // background: linear-gradient(90deg, var(--dark) -40%, transparent 50%, transparent);
    justify-content: space-between;
  }

  &__event {
    display: flex;
  }

  &__date {
    color: var(--lighter);
    padding: .5em 1em;
  }

  &__action {
    padding: 5px 0;
  }

  &__center {
    display: flex;
    justify-content: center;
    margin-top: 20px;
  }

  &__btn {
    width: 350px;
    margin: 0 10px;

    &.--right {
      display: flex;
      justify-content: flex-end;
    }
  }

  .current-state {
    display: flex;
    align-items: center;
    // justify-content: flex-end;
    min-width: 300px;
    gap: 20px;

    .executed,
    .completed {
      display: inline-flex;
      align-items: center;
      // margin-left: 10px;
      color: var(--warning);

      svg {
        margin-left: 5px;
      }
    }

    .executed {
      color: #39b022;
    }
  }
}
