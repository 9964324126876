.doc-style {
  li[name] {
    // border: solid 1px var(--medium);
    margin: 5px;
    position: relative;
    // background: #64c7cc24;

    &:after {
      // content: attr(name);
      position: absolute;
      background: var(--dark-orange);
      color: white;
      z-index: 1;
      top: 0;
      left: -40px;
    }
  }

  .show {
    transform: scale(1.1, 1.1);
    transition: .5s;
    border: solid 1px var(--medium);
  }

  .hide {
    // background: red;
    transform: scale(.5, .5);
    transition: .5s;
  }

  //---------------------------------------------------------------------------
}
