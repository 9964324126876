.form-state-group {
  padding-bottom: 1em;

  & > header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-top: 10px;
    color: var(--lighter);

    &.active {
      color: white;
    }
  }

  &__header {
    padding: 0 10px;
    font-size: var(--text-big);
    text-align: right;

    .small {
      font-size: var(--text-small);
      font-weight: normal;
    }
  }

  .btn.clear {
    color: var(--darker);
  }
}

.state-completed {
  border-radius: 5px;

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: var(--darker);
    padding: 1em 0;

    p {
      margin-bottom: 0;
      color: var(--dark);
    }
  }

  &__title {
    // padding: 5px 0;
    font-size: var(--text-big);
    font-weight: normal;
    color: var(--darker);
  }

  &__line {
    // display: flex;
  }
}
