.p1 {
  // --sub-header: #303f50;
  --sub-header: #2e425c;
  --sub-header: var(--primary);

  --tag-1: #ceecfd;
  --tag-1-text: #2e87ba;
  --tag-2: #d6ede2;
  --tag-2-text: #13854e;
  --tag-3: #ceecfd;
  --tag-3-text: #2d86ba;
  --tag-4: #f2dcf5;
  --tag-4-text: #a734ba;
  --purple: #7784ee;

  &__header {
    align-items: center;
    background: var(--sub-header);
    color: #fff;
    display: flex;
    gap: 2em;
    padding: 1em;
    position: sticky;
    top: -35px;
    z-index: 1;

    h1 {
      padding: .25em 0;
      margin-left: .5rem;
    }

    &::before {
      content: '';
      position: absolute;
      left: 0;
      right: 0;
      top: 100%;
      height: 110px;
      background: var(--sub-header);
      margin-top: -20px;
    }
  }

  &__content {
    padding: 1em;

    & > header {
      padding: .5em 0;
    }
  }

  &.small {
    .p1__header:before {
      height: 65px;
    }

    .p1__content {
      padding: .5em 1em;
    }
  }

  &.basic {
    .p1__header:before {
      content: none;
    }

    .p1_content {
      padding: 1em;
    }
  }

  &__actions {
    align-items: center;
    background: var(--light-blue);
    border-radius: 50vh;
    display: flex;
    gap: .5em;

    button,
    a {
      background: none;
      border-radius: 50vh;
      color: var(--dark);
      font-size: var(--text-medium);
      padding: .5em 2em;

      &.active {
        background: var(--dark2);
        color: #fff;
      }
    }
  }
}

.turn {
  display: flex;
  align-items: center;
  gap: .5em;
  // border: solid 1px red;
  &:before {
    content: '';
    width: 15px;
    height: 15px;
    border-radius: 50vh;
    background: var(--grey);
  }

  &.cpTurn:before {
    background: var(--warning);
  }

  &.myTurn:before {
    background: var(--success);
  }
}
