.date-picker {
  position: relative;
  // flex: 1;
  // display: flex;

  &__bg {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    z-index: 200;
  }

  &__input {
    display: flex;
    align-items: center;

    svg {
      width: 32px;
      height: 32px;
    }
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 5px;
    font-size: var(--text-big);
    background: var(--grey);
    border-radius: 5px;
    // margin: -1px;

    .month {
      visibility: hidden;
    }

    button {
      background: none;
      color: black;
    }

    &__center {
      display: flex;
      align-items: center;
      cursor: pointer;
      user-select: none;
      padding: 5px 10px;
      border-radius: 5px;
      transition: .3s;

      &.active,
      &:hover {
        background: var(--light-grey);
      }
    }
  }

  &__calendar {
    position: absolute;
    // border: solid 1px #ccc;
    z-index: 300;
    background: white;
    // padding: 5px;
    border-radius: 5px;
    box-shadow: 0 0 15px 1px var(--darker);
    margin-top: 15px;
    min-width: 370px;

    &:before {
      content: '';
      width: 15px;
      height: 15px;
      top: -8px;
      left: 30px;
      // background: white;
      background: var(--grey);
      position: absolute;
      transform: rotate(45deg);
      border-top-left-radius: 3px;
      border-bottom-right-radius: 3px;
      z-index: -1;
      box-shadow: 0 0 15px 0px var(--darker);
    }
  }

  &__weekdays,
  &__days {
    display: grid;
    grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
  }
  &__weekdays {
    padding: 10px 0;
  }

  &__days {
    margin: 10px 0;
  }

  &__w-cell {
    margin: 5px;
    border-radius: 50vh;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__cell {
    margin: 5px;
    border-radius: 50vh;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: var(--text-medium);

    &.current-month {
      cursor: pointer;
      &:hover {
        background: var(--grey);
      }
    }

    &.today {
      border: 1px solid var(--link);
    }

    &.weekend {
      color: #f18f8f;
    }

    &.selected {
      background: var(--dark);
      color: white;
      transition: 0.3s;
      &:hover {
        background: var(--darker);
      }
    }
  }
}
